import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'UI/common/components/Button';
import { ModalText } from '../steps/financialReport/FileErrorModal/styled';
import { ModalContentContainer, ModalHeader } from './styled';

const DEFAULT_TEXT = 'Error Occured';
export const ModalContent = ({ title, text, handleCloseModal }) => {
  const { t } = useTranslation();

  return (
    <ModalContentContainer>
      <ModalHeader>
        {title || DEFAULT_TEXT}
      </ModalHeader>
      {text && (
      <ModalText>
        {text}
      </ModalText>
      )}
      <Button onClick={handleCloseModal}>
        {t('tryAgain')}
      </Button>
    </ModalContentContainer>
  );
};
