import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledBackground = styled.div`
  position: relative;
  z-index: 1;
  background: linear-gradient(#E5EFFF 0%, rgba(229, 239, 255, 0.2627) 83.7%, rgba(229, 239, 255, 0) 100%);
`;

export const StyledContainer = styled.div`
  max-width: 1120px;
  padding: 0 15px;
  margin: 0 auto;
  
  .mobile {
    display: none;
  }

  @media (max-width: 768px) {

    max-width: 1090px;
    padding: 0;
    
    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
      flex-direction: column;
      padding: 24px 30px 32px 16px;     

      .copyright {
        font-size: 14px;
        color: #757D94;
      }
    }
  }
`;

export const SocialMobWrapper = styled.div`
  margin: 35px 0 20px;
  
  > div {
    gap: 50px;
    @supports not (aspect-ratio: 1 / 1) {
      a {
        margin-right: 50px;
      }
  }
  
`;

export const StyledContent = styled.div`
  padding: 44px 0 16px 0;
`;

export const StyledLogo = styled.img`
  margin-bottom: 24px;
  max-width: 122px;
  width: 100%;
`;

export const FirstTwoBlockWrapper = styled.div`
  flex: 0 1 40%;
`;

export const FirstColumn = styled.div`
  font-size: 14px;
  line-height: 24px;

  & > span {
    margin-bottom: 8px;
  }

  .address {
    span {
      display: block;
    }
  }

  .link {
    display: block;
    cursor: pointer;

    span {
      :hover {
        transition: all 0.3s;
        color: ${(props) => props.theme.colors?.hoverColor}
      }
    }
  }

  .tel {
    margin: 8px 0;
  }
`;

export const SecondColumn = styled.div`
`;

export const StyledLink = styled(NavLink)`
  display: block;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 20px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    font-weight: 700;
  }

  :hover {
    transition: all 0.3s;
    color: ${(props) => props.theme.colors?.hoverColor}
  }
`;

export const ThirdColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 144px;  

  .copyright {
    span {
      font-size: 14px;
      display: block;
      line-height: 24px;
      color: #757D94;
    }
  }
`;

export const FirstColumnMob = styled.div`
  width: 185px;
  max-height: fit-content;

  p {
    font-size: 14px;
    line-height: 24px;
    color: #272D4E;

    &:first-child {
      margin-bottom: 16px;
    }
  }

`;

export const SecondColumnMob = styled.div`
  max-height: fit-content;

  p {
    font-size: 14px;
    line-height: 24px;
    color: #272D4E;

    &:first-child {
      margin-bottom: 16px;
    }
  }

  .mail, .phone {
    a {
      &:hover {
        cursor: pointer;
        color: #815EFD;
        text-decoration: underline;
      }
    }
  }
`;
