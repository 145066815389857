import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import img404 from 'UI/assets/images/404.png';
import girlOops from 'UI/assets/images/girlOops.png';
import { Button } from 'UI/common/components/Button';
import { StyledExpandedContainer } from 'UI/components/MainLayout/styled';
import { HelmetWrapper } from 'components/HelmetWrapper/HelmetWrapper';
import { Social } from 'UI/common/components/Social/Social';
import {
  Container404, Girl, NotFoundContainer, StyledContainer, StyledContent,
} from './styled';

export const NotFound = () => {
  const navigate = useNavigate();
  const handleRedirectToHome = () => navigate('/');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!i18n.language) {
      i18n.changeLanguage('en');
    }
  }, [i18n]);

  return (
    <>
      <HelmetWrapper />
      <StyledExpandedContainer>
        <StyledContainer>
          <StyledContent>
            <NotFoundContainer>
              <div className="NFcontent">
                <h2>404</h2>
                <p>{t('pageNotFound')}</p>
                <Button onClick={handleRedirectToHome}>{t('backToHomeBtn')}</Button>
                <Social />
              </div>
              <Container404>
                <img src={img404} alt="" />
              </Container404>
              <Girl>
                <img src={girlOops} alt="" />
              </Girl>
            </NotFoundContainer>
          </StyledContent>
        </StyledContainer>
      </StyledExpandedContainer>
    </>
  );
};
