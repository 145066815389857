export default {
  // eslint-disable-next-line consistent-return
  getItem(...args) {
    try {
      return localStorage.getItem(...args);
    // eslint-disable-next-line no-empty
    } catch (_) {}
  },
  setItem(...args) {
    try {
      localStorage.setItem(...args);
    // eslint-disable-next-line no-empty
    } catch (_) {}
  },
  removeItem(...args) {
    try {
      localStorage.removeItem(...args);
    // eslint-disable-next-line no-empty
    } catch (_) {}
  },
};
