import styled from 'styled-components';

export const InputRangeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
`;

export const StyledRange = styled.input`
  display: block;

  /* The slider itself */
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 8px; /* Specified height */
  background: #E9F1FF;
  border-radius: 6px;
  outline: none; /* Remove outline */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;

  &[type="range"] {
    -webkit-appearance: none;
    margin-right: 15px;
    height: 20px;
    padding: 6px 0;
    background: transparent;
  }

  /* Input Thumb */
  &[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    background: #815EFD;
    cursor: pointer;
    transition: background .3s ease-in-out;
  }

  &[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    background: #815EFD;
    cursor: pointer;
    transition: background .3s ease-in-out;
  }

  &[type="range"]::-ms-thumb {
    -webkit-appearance: none;
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    background: #815EFD;
    cursor: ew-resize;
    transition: background .3s ease-in-out;
  }

  &[type="range"]::-webkit-slider-thumb:hover {
    background: #815EFD;
  }

  &[type="range"]::-moz-range-thumb:hover {
    background: #815EFD;
  }

  &[type="range"]::-ms-thumb:hover {
    background: #815EFD;
  }

  /* Input Track */
  &[type="range"]::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
  }

  &[type="range"]::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: #E9F1FF;
  }

  &[type="range"]::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: #E9F1FF;
  }
  
  &[type="range"]::-webkit-slider-container {
    background: #E9F1FF;
    border-radius: 20px;
    background-image: linear-gradient(#607B9B, #607B9B);
    background-size: ${({ bgWidth }) => bgWidth || 0}% 100%;
    background-repeat: no-repeat;
  }
`;
