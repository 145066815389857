import styled from 'styled-components';

export const SixthStepItem = styled.div`
  width: 716px;
  height: 394px;
  position: relative;
  display: flex;
  flex-direction: column;      
  padding: 40px 0 0 64px;  
  
  button {
    width: 180px;
    height: 48px;    
    padding: 13px 0;  
  }

  @media (max-width: 768px) {
    width: calc(100% + 30px);
    padding: 0 0 0 14px;    
  }

  @media (max-width: 425px) {
    margin: -15px;
  }

`;

export const SixthStepHeader = styled.h3`
  font-weight: 700;
  font-size: 28px;
  color: ${(props) => props.theme.colors?.mainColor};
  margin-bottom: 24px;
`;

export const SixthStepTitle = styled.p`
  font-size: 18px;
  margin-bottom: 27px;
  max-width: 350px;
`;

export const ThankYouImg = styled.img`
  position: absolute;
  left: 413px;
  top: 50px;
  width: 323px;
  height: 344px;

  @media (max-width: 768px) {
    width: 219px;
    height: 233px;
    top: 226px;
    left: unset;
    right: -43px;
  }  
`;
