import { applyLocale, periodFormatter } from './helpers';

export const offerSummaryData = [
  {
    blockTitle: 'hiddenTitle',
    blockFields: [
      {
        title: 'fullName',
        value: 'userName',
        maskChar: '',
        visible: true,
        formatFn: (v) => v,
      },
      {
        title: 'emailAddress',
        value: 'contactEmail',
        maskChar: '',
        visible: true,
        formatFn: (v) => v,
      },
      {
        title: 'summaryPhoneNumber',
        value: 'contactPhoneNumber',
        maskChar: '',
        visible: true,
        formatFn: (v) => v,
      },
    ],
  },
  {
    blockTitle: 'companyInformation',
    blockFields: [
      {
        title: 'companyName',
        value: 'companyId',
        maskChar: '',
        visible: true,
        formatFn: (v) => v,
      },
    ],
  },
  {
    blockTitle: 'selectedOffer',
    blockFields: [
      {
        title: 'summaryPeriod',
        value: 'loanTermInMonths',
        maskChar: '',
        visible: true,
        formatFn: periodFormatter,
      },
      {
        title: 'summaryAmount',
        value: 'amount',
        maskChar: ' SEK',
        visible: true,
        formatFn: applyLocale,
      },

    ],
  },
  {
    blockTitle: 'creditDetails',
    blockFields: [
      {
        title: 'financialReports',
        value: 'reportFilesNames',
        maskChar: '',
        visible: true,
        formatFn: (v) => v,
      },
      {
        title: 'summaryCompanyBusiness',
        value: 'companyBusiness',
        maskChar: '',
        visible: true,
        formatFn: (v) => v,
      },
      {
        title: 'summaryLoanPurpose',
        value: 'loanPurpose',
        maskChar: '',
        visible: true,
        formatFn: (v) => v,
      },
    ],
  },
  {
    blockTitle: 'guarantorInformation',
    blockFields: [
      {
        title: 'SSNumber',
        value: 'social_security_number',
        maskChar: '',
        visible: true,
        formatFn: (v) => v,
      },
      {
        title: 'fullName',
        value: 'full_name',
        maskChar: '',
        applyLocale: false,
        visible: true,
        formatFn: (v) => v,
      },
      {
        title: 'summaryEmail',
        value: 'email',
        maskChar: '',
        visible: true,
        formatFn: (v) => v,
      },
    ],
  },
];
