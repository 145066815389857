import styled from 'styled-components';

import people from 'UI/assets/images/people.webp';
import peopleMob from 'UI/assets/images/peopleMob.webp';
import faqGradient from 'UI/assets/images/mapBg.png';

export const StyledContainer = styled.div`
  max-width: 1120px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  
  h3 { 
    text-align: center
  }

  @media (max-width: 768px) {   
    padding: 0;
  }

  .trustpilot-widget {
    margin-top: 120px;

    @media (max-width: 768px) {
      margin-top: 140px;
    }

    @media (max-width: 500px) {
      margin-top: 70px;
    }
  }
`;

export const PeopleBlock = styled.div`
  position: absolute;
  top: 60px;
  right: 13px;
  width: 513px;
  height: 595px;
  background-image: url(${people});
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-width: 1024px) {
    transform: translateX(170px);
  }

  @media (max-width: 768px) {
    top: 35px;
    right: 10px;
    width: 350px;
    height: 400px;
    transform: unset;
    img {
      width: 350px;
    }    
  }

  @media (max-width: 600px) {
    right: -85px;
  }
  
   @media (max-width: 500px) {
     top: 79px;
     right: -136px;
     background-image: url(${peopleMob});
     width: 280px;
     height: 325px;
    img {
      width: 280px;
    }
  }
`;

export const StyledFaqContainer = styled.div`
  background-image: url(${faqGradient});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 768px) {
    padding-top: 80px;
  }
`;
