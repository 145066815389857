import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import 'yup-personnummer';
import {
  FieldArray, Form, Formik, getIn,
} from 'formik';

import { Button } from 'UI/common/components/Button';
import { inputTypeNumberInterceptor, STEP } from 'constants/helpers';
import { fieldsStore } from 'store/forms';
import { Input } from 'UI/common/components/Input/Input';
import addNew from 'UI/assets/images/icons/addNew.svg';
import removeItem from 'UI/assets/images/icons/removeItem.svg';
import message from 'UI/assets/images/icons/Message.svg';
import profile from 'UI/assets/images/icons/Profile.svg';
import work from 'UI/assets/images/icons/work.svg';
import {
  FourthStepAction,
  FourthStepBody,
  FourthStepHeader,
  FourthStepItem,
  RemoveGuarantor,
  AddNewGuarantor,
} from 'UI/components/pages/LoanStepper/steps/guarantors/styled';
import { useFetch } from 'use-http';
import { BASE_API_URL, BASE_FETCH_SETTINGS, OFFERS_URL } from 'constants/apiConstants';
import { offersStore } from 'store/offers';
import { Loader } from 'UI/common/components/Loader/Loader';
import { Flex } from 'UI/common/components/Flex';

export const GuarantorsSelection = () => {
  const [formState, setFormState] = useRecoilState(fieldsStore);
  const offerState = useRecoilValue(offersStore);
  const { t } = useTranslation();
  const { post, response, loading } = useFetch(BASE_API_URL, BASE_FETCH_SETTINGS);

  const initialValues = {
    guarantors: [
      {
        email: '',
        social_security_number: '',
        full_name: '',
        id: Date.now(),
      },
    ],
  };

  Yup.addMethod(Yup.array, 'unique', function (errorMessage) {
    // eslint-disable-next-line react/no-this-in-sfc
    return this.test('unique', function (list) {
      const mapper = (x) => x.socialSecurityNumber;
      const set = [...new Set(list.map(mapper))];
      const isUnique = list.length === set.length;
      if (isUnique) {
        return true;
      }

      const idx = list.findIndex((obj, i) => mapper(obj) !== set[i]);
      // eslint-disable-next-line react/no-this-in-sfc
      return this.createError({
        path: `guarantors[${idx}].social_security_number`,
        message: errorMessage,
      });
    });
  });

  const validationSchema = Yup.object().shape({
    guarantors: Yup.array().of(
      Yup.object().shape({
        email: Yup.string()
          .email(t('inputErrorEmail'))
          .required(t('inputErrorRequired')),
        social_security_number: Yup.string()
          .matches(/^(\d{10}|\d{12})$/, t('inputErrorExactly'))
          .test('validate-same-ssn', (value, { createError, path }) => {
            if (value === formState.ssn) {
              return createError({
                path,
                message: t('invalidGuarantorSsn'),
              });
            }
            return true;
          })
          .required(t('inputErrorRequired'))
          .personnummer(t('invalidUserSsn')),
        full_name: Yup.string()
          .required(t('inputErrorRequired'))
          .matches(/^\D*$/, t('inputErrorName'))
          .min(2, t('inputErrorShort'))
          .max(30, t('inputErrorLong'))
          .matches(/^\s*\S.*$/, t('inputErrorSpaces')),
      }),
    )
      .unique(t('duplicateSsn')),
  });

  const inputData = [
    {
      name: 'social_security_number',
      placeholder: t('SSNumber'),
      icon: work,
      type: 'text',
      onlyNum: true,
    },
    {
      name: 'full_name',
      placeholder: t('fullName'),
      icon: profile,
      type: 'text',
      onlyNum: false,
    },
    {
      name: 'email',
      placeholder: t('emailAddress'),
      icon: message,
      type: 'email',
      onlyNum: false,
    },
  ];

  const proceedToNextStep = async (values) => {
    const requestBody = {

      guarantors: values.guarantors,
      passed_step: STEP.LoanGuarantee,

    };
    await post(`${OFFERS_URL}${offerState.offerId}/guarantors/`, requestBody);
    if (response.ok) {
      setFormState({ ...formState, ...values, currentStep: 5 });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur
      onSubmit={proceedToNextStep}
    >
      {({
        values, errors, isValid, touched, dirty, handleChange, handleBlur, handleSubmit,
      }) => (
        <FourthStepItem>
          <FourthStepHeader>{t('fillGuarantorInf')}</FourthStepHeader>
          {loading && <Flex justify="center"><Loader /></Flex>}
          { !loading && (
          <Form>
            <FieldArray name="guarantors">
              {({ push, remove }) => (
                <div>
                  {values.guarantors.map((guarantor, index) => (
                    <FourthStepBody key={`guarantor-block-${guarantor.id}`}>
                      {values.guarantors.length > 1
                            && (
                            <RemoveGuarantor onClick={() => remove(index)}>
                              <p>{t('removeGuarantors')}</p>
                              <img src={removeItem} alt="remove" />
                            </RemoveGuarantor>
                            ) }
                      {inputData.map((item) => (
                        <Input
                          key={`input-name-${item.name}`}
                          data={item}
                          name={`guarantors[${index}].${item.name}`}
                          handleChange={item.onlyNum
                            ? inputTypeNumberInterceptor(handleChange) : handleChange}
                          handleBlur={handleBlur}
                          maxWidth="none"
                          value={guarantor[`guarantors[${index}].${item.name}`]}
                          touched={getIn(touched, `guarantors[${index}].${item.name}`)}
                          errors={getIn(errors, `guarantors[${index}].${item.name}`)}
                        />
                      ))}
                    </FourthStepBody>
                  ))}
                  {values.guarantors.length < 5
                  && (
                  <AddNewGuarantor onClick={() => push({
                    email: '',
                    socialSecurityNumber: '',
                    fullName: '',
                    id: Date.now(),
                  })}
                  >
                    <p>{t('addGuarantors')}</p>
                    <img src={addNew} alt="addNew" />
                  </AddNewGuarantor>
                  ) }
                </div>
              )}
            </FieldArray>
          </Form>
          )}
          { !loading && (
          <FourthStepAction>
            <Button
              formButton
              type="submit"
              onClick={handleSubmit}
              className={!(dirty && isValid) && 'disabled-btn'}
              disabled={!(dirty && isValid)}
            >
              {t('nextBtn')}
            </Button>
          </FourthStepAction>
          )}
        </FourthStepItem>
      )}
    </Formik>
  );
};
