import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const offersStore = atom({
  key: 'offersState',
  default: {},
  effects_UNSTABLE: [persistAtom],
});
