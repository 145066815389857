import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';

import { usePathMatch } from 'customHooks/usePathMatch';
import { fieldsStore } from 'store/forms';
import ellipse from 'UI/assets/images/ellipse.webp';
import mobEcllipse from 'UI/assets/images/mobEcllipse.webp';

const Ellipse = styled.div`
  position: absolute;
  background-image: url(${ellipse});
  background-image: ${({ hideEllipse }) => (hideEllipse && 'unset')};
  width: 749px;
  height: 749px;
  background-repeat: no-repeat;
  background-size: contain;
  top: 0;
  right: 0;

  @media (max-width: 768px) {
    background-image: url(${mobEcllipse});
    background-image: ${({ hideEllipse }) => (hideEllipse && 'unset')};
    width: 405px;
    height: 405px;
    top: 10px;
    right: -154px;
  }
`;

export const EllipseBlock = () => {
  const [formState] = useRecoilState(fieldsStore);
  const isLoanOrder = usePathMatch('bestallningsblankett-for-lan', 'en/loan-order-form');
  const isErrorPage = usePathMatch('404', 'en/404');

  const hideEllipse = useMemo(() => (
    (isLoanOrder && formState.currentStep !== 5) || isErrorPage
  ), [isLoanOrder, isErrorPage, formState.currentStep]);

  return (
    <Ellipse hideEllipse={hideEllipse} />
  );
};
