import React from 'react';
import styled from 'styled-components';

const StyledSpanText = styled.span`
  color: ${(props) => props.color || props.theme.colors?.subTitle};
  margin: ${(props) => props.margin || '0'};
  font-size: ${(props) => props.size || '20px'};
  font-weight: 400;
  display: ${(props) => props.display || 'inline-block'};
  white-space: pre-line;

  @media (max-width: 768px) {
    font-size: 16px;
    margin: 16px 0 24px;
  }
`;

export const SpanText = (props) => <StyledSpanText {...props} />;
