import styled from 'styled-components';

export const StyledBorderContainer = styled.div`
  width: 86px;
  height: 44px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors?.mainColor};
  border-radius: 5px;
  margin-left: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

   @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const StyledFlag = styled.div`
  display: flex;
  align-items: center;

  .FlagBlock {
    width: 26px;
    height: 16px;
    margin-right: 8px;
  }

  .country {
    font-size: 14px;
    font-weight: 600;
  }
`;
