import styled from 'styled-components';

export const ModalContentContainer = styled.div`
  position: relative;
  z-index: 1001;  
  width: 100%;
  height: fit-content;
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  button {
    width: 260px;
    height: 54px;    
  }  
`;

export const ModalHeader = styled.h4`
  text-align: center;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors?.mainColor};
`;

export const ModalTitle = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 44px;
  color: #607B9B;
`;
