import React, { useState, useEffect, useMemo } from 'react';
import {
  Formik,
} from 'formik';
import { useTranslation } from 'react-i18next';
import { Button } from 'UI/common/components/Button';
import * as Yup from 'yup';
import addNew from 'UI/assets/images/icons/addNew.svg';
import removeIcon from 'UI/assets/images/icons/cross.svg';

import { Loader } from 'UI/common/components/Loader/Loader';
import { Flex } from 'UI/common/components/Flex';
import Collapsible from 'react-collapsible';
import {
  FormHeader,
  Form as BaseForm,
  FormItem,
  FormLabel,
  TextArea,
  StyledError,
  StyledOptions,
  OptionsLabel,
  AddAttachmentWrapper,
  FormBlock,
  AddFileButton,
  OptionsInput,
  RemoveFileBtn,
  StyledCollapsible,
} from './styled';
import { FileErrorModal } from '../FileErrorModal/FileErrorModal';

const bytesToSize = (sizeInBytes) => sizeInBytes / (1024 * 1024);
const MAX_FILE_SIZE = 15;
const SUPPORTED_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/plain',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];
const MAX_FILES_COUNT = 5;
const OPTIONS = { YES: 'Yes', NO: 'No' };
const toName = (file) => file.name;

export const Form = ({ loading, onFormSubmit }) => {
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const { t } = useTranslation();
  const initialValues = {
    shouldUpload: '',
    companyBusiness: '',
    loanPurpose: '',
    reportFiles: null,
  };

  const validationSchema = Yup.object().shape({
    companyBusiness: Yup.string().required(t('inputErrorRequired')).min(6, t('inputErrorShort'))
      .max(1500, t('inputErrorLong')),
    loanPurpose: Yup.string().required(t('inputErrorRequired')).min(6, t('inputErrorShort'))
      .max(1500, t('inputErrorLong')),
    reportFiles: Yup.mixed().nullable()
      .test(
        'FILES_COUNT',
        (files, { createError }) => {
          if (!files) return true;
          const redundantFiles = files.slice(MAX_FILES_COUNT);
          return files.length <= MAX_FILES_COUNT
        || createError({
          message: {
            title: t('tooManyFiles'),
            errorFiles: redundantFiles.map(toName),
            createErrorMessage: () => t('amountCanUpload'),
          },
        });
        },
      )
      .test(
        'FILE_SIZE',
        (files, { createError }) => {
          if (!files) return true;
          const oversizedFiles = files.filter((file) => bytesToSize(file.size) > MAX_FILE_SIZE);
          return !oversizedFiles.length || createError({
            message: {
              title: t('tooLargeFile'),
              errorFiles: oversizedFiles.map(toName),
              createErrorMessage: () => (
                <>
                  {t('file')}
                  &nbsp;
                  <span>{oversizedFiles[0]?.name}</span>
                  &nbsp;
                  {t('tooLargeFileErrorText')}
                </>
              ),
            },
          });
        },
      )
      .test('FILE_FORMAT', (files, { createError }) => {
        if (!files) return true;
        const unsupportedFiles = files.filter((file) => !SUPPORTED_TYPES.includes(file.type));
        return !unsupportedFiles.length || createError({
          message: {
            title: t('wrongFileType'),
            errorFiles: unsupportedFiles.map(toName),
            createErrorMessage: () => (
              <>
                {t('file')}
                &nbsp;
                <span>{unsupportedFiles[0].name}</span>
                &nbsp;
                {t('unsupportedFileErrorText')}
              </>
            ),
          },
        });
      }),

  });

  const triggerFileUpload = () => {
    if (hiddenFileInput.current) { hiddenFileInput.current.click(); }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
      validateOnBlur
      validateOnChange
    >
      { ({
        values,
        errors,
        touched,
        isValid,
        dirty,
        setFieldValue,
        handleSubmit,
        handleChange,
        handleBlur,
        setErrors,
        validateField,

      }) => {
        const continueBtnDisabled = !(dirty && isValid
          && (values.shouldUpload === OPTIONS.NO || !!values.reportFiles));
        const currentFileNames = values.reportFiles?.map(toName) || [];

        const addBtnDisabled = useMemo(() => values.shouldUpload !== OPTIONS.YES || loading, [values.shouldUpload, loading]);

        const checkInputError = (name) => !!errors[name] && touched[name];

        const handleFileDelete = (fileName) => {
          const filteredFiles = values.reportFiles.filter((file) => file.name !== fileName);
          setFieldValue('reportFiles', filteredFiles.length ? filteredFiles : null, true);
        };

        const handleFileInputChange = (e) => {
          const { files } = e.target;
          const currentFiles = values.reportFiles;
          const newFiles = Array.from(files)
            .filter((f) => !currentFileNames.includes(f.name));

          setFieldValue('reportFiles', !currentFiles ? newFiles
            : [...currentFiles, ...newFiles],
          true);
        };

        const resetFiles = () => {
          hiddenFileInput.current.value = null;
        };

        const handleHideFileError = () => {
          setErrorModalVisible(false);
          setErrors({ reportFiles: null });
          setFieldValue('reportFiles', null);
        };

        const handleErrorModalChange = () => {
          setErrorModalVisible(false);
          validateField('reportFiles');
        };

        useEffect(() => {
          if (errors?.reportFiles) {
            setFieldValue('reportFiles', values.reportFiles?.filter((f) => !errors.reportFiles.errorFiles.includes(f.name)), false);
            resetFiles();
            setErrorModalVisible(true);
          }
        }, [errors]);

        return (
          <>
            <BaseForm onSubmit={handleSubmit}>
              <FormBlock>
                <FormHeader>{t('financialReports')}</FormHeader>
                <FormLabel htmlFor="yesUpload">
                  {t('shareFreshReports')}
                </FormLabel>
                <StyledOptions>
                  <StyledCollapsible>
                    <div className="options-item">
                      <OptionsInput
                        id="yesUpload"
                        type="radio"
                        name="uploadFile"
                        value={OPTIONS.YES}
                        disabled={loading}
                        checked={values.shouldUpload === OPTIONS.YES}
                        onChange={() => setFieldValue('shouldUpload', OPTIONS.YES)}
                      />
                      <OptionsLabel htmlFor="yesUpload">{t('btnYes')}</OptionsLabel>
                    </div>
                    <Collapsible open={values.shouldUpload === OPTIONS.YES}>
                      <AddAttachment
                        currentFileNames={currentFileNames}
                        addBtnDisabled={addBtnDisabled}
                        onAddBtnClick={triggerFileUpload}
                        onDeleteBtnClick={handleFileDelete}
                      />
                    </Collapsible>
                  </StyledCollapsible>
                  <input
                    ref={hiddenFileInput}
                    type="file"
                    name="reportFiles"
                    multiple
                    style={{ display: 'none' }}
                    onChange={handleFileInputChange}
                  />
                  <div className="options-item">
                    <OptionsInput
                      id="noUpload"
                      type="radio"
                      name="uploadFile"
                      value={OPTIONS.NO}
                      disabled={loading}
                      checked={values.shouldUpload === OPTIONS.NO}
                      onChange={() => {
                        setFieldValue('shouldUpload', OPTIONS.NO);
                        setFieldValue('reportFiles', null);
                        resetFiles();
                      }}
                    />
                    <OptionsLabel htmlFor="noUpload">{t('btnNo')}</OptionsLabel>
                  </div>
                </StyledOptions>
              </FormBlock>
              <FormBlock>
                <FormHeader>{t('businessDescription')}</FormHeader>
                <FormItem>
                  <FormLabel htmlFor="companyBusiness">{t('pleaseDescribeBusiness')}</FormLabel>
                  <TextArea
                    id="companyBusiness"
                    name="companyBusiness"
                    placeholder={t('descriptionPlacehoder')}
                    hasError={checkInputError('companyBusiness')}
                    value={values.companyBusiness}
                    disabled={loading}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <StyledError name="companyBusiness" visible={checkInputError('companyBusiness')}>
                    { errors.companyBusiness }
                  </StyledError>
                </FormItem>
                <FormItem>
                  <FormLabel htmlFor="loanPurpose">{t('loanPurpose')}</FormLabel>
                  <TextArea
                    id="loanPurpose"
                    name="loanPurpose"
                    placeholder={t('loanPurposePlaceholder')}
                    hasError={checkInputError('loanPurpose')}
                    value={values.loanPurpose}
                    disabled={loading}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <StyledError name="loanPurpose" visible={checkInputError('loanPurpose')}>
                    { errors.loanPurpose }
                  </StyledError>
                </FormItem>
              </FormBlock>
              {loading ? <Flex justify="center"><Loader /></Flex> : (
                <Button type="submit" formButton disabled={continueBtnDisabled}>
                  {t('nextBtn')}
                </Button>
              )}
            </BaseForm>
            <FileErrorModal
              visible={errorModalVisible}
              title={errors.reportFiles?.title}
              text={errors.reportFiles?.createErrorMessage()}
              onClose={handleHideFileError}
              onRestart={handleErrorModalChange}
            />
          </>
        );
      } }
    </Formik>
  );
};

export const AddAttachment = ({
  currentFileNames = [], addBtnDisabled, onAddBtnClick, onDeleteBtnClick,
}) => {
  const { t } = useTranslation();
  return (
    <AddAttachmentWrapper>
      <AddFileButton
        type="button"
        className="add-btn"
        disabled={addBtnDisabled}
        onClick={onAddBtnClick}
      >
        <span>{t('addAttachment')}</span>
        <img src={addNew} alt="Add icon" />
      </AddFileButton>
      <div className="info-items">
        <div className="info-item">{t('filesAmountLimit')}</div>
        <div className="info-item">{t('fileMaxSize')}</div>
        <div className="info-item">{t('allowedFileTypes')}</div>
      </div>
      <div className="files">
        { currentFileNames.map((fileName) => (
          <div key={fileName} className="file">
            <span className="file-name">{fileName}</span>
            <RemoveFileBtn type="button" disabled={addBtnDisabled} onClick={() => onDeleteBtnClick(fileName)}>
              <img src={removeIcon} className="remove-btn" alt="removeIcon" />
            </RemoveFileBtn>
          </div>
        ))}
      </div>

    </AddAttachmentWrapper>
  );
};
