import React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.div`
  width: 64px;
  height: 64px;
  background: ${(props) => props.theme.backgrounds?.iconBackground};
  margin-bottom: 16px;
  border-radius: 10px;
  font-size: 46px;
  font-weight: 800;
  color: ${(props) => props.theme.colors?.readMoreColorBtn};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconBlock = (props) => <StyledIcon {...props} />;
