import styled from 'styled-components';

export const StyledContainer = styled.div`
  max-width: 1090px;
  margin: 0 auto;
`;
export const StyledContent = styled.div`
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 182px;

  @media (max-width: 625px) {
    padding-top: 30px;
    padding-bottom: 90px;
  }
`;

export const NotFoundContainer = styled.div`
  max-width: 716px;
  height: 394px;  
  position: relative;
  margin: 0 auto;

  .NFcontent {
    display: flex;
    flex-direction: column;
    max-width: 230px;
    margin-left: 64px;

    h2 {
      margin-top: 73px;
      font-size: 78px;
      font-weight: 700;
    }

    button {
      padding: 13px 15px;
      margin: 24px 0;
    }   
  }

  @media (max-width: 625px) {

    .NFcontent {
      margin-left: 24px;      
      
      h2 {
        font-size: 28px;
        margin-bottom: 24px;
      }
      
      p {
        font-size: 18px;        
      }
    }
  }
`;

export const Girl = styled.div`
  position: absolute;
  bottom: -5px;
  right: 90px;
  @media (max-width: 625px) {
    bottom: -30px;
    right: -15px;
    img {
      width: 157px;
      height: 244px;
    }
  }
`;
export const Container404 = styled.div`
  position: absolute;
  bottom: -5px;
  right: 0;

  @media (max-width: 625px) {
    bottom: -30px;
    
    img {
      width: 98vw;
      height: 130px;
    }
  }
`;
