import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import {
  ModalWrapper,
  ModalContentWrapper, CloseIcon,
} from 'UI/common/components/Modal/styled';

export const ModalWindow = ({ children, closeAction = () => null, showIcon = false }) => {
  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  return ReactDOM.createPortal(
    <ModalWrapper onClick={closeAction}>
      {showIcon && (
        <CloseIcon onClick={closeAction} />
      )}
      <ModalContentWrapper onClick={(e) => e.stopPropagation()}>
        {children}
      </ModalContentWrapper>
    </ModalWrapper>,
    document.getElementById('portal'),
  );
};
