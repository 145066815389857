import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import { useTranslation } from 'react-i18next';

import { Flex } from 'UI/common/components/Flex';
import { BlockTitle } from 'UI/common/components/Titles/BlockTitle';
import arrowUp from 'UI/assets/images/icons/arrowUp.svg';
import arrowDown from 'UI/assets/images/icons/arrowDown.svg';
import {
  AccordionItemContent,
  AccordionItemHeader,
  AccordionItemTitle,
  AccordionsWrapper,
  StyledContainer,
} from './styled';

const prepareInitState = (arr) => arr.map((item) => ({ id: item.id, value: false }));

export const Faq = ({ title, faqArr }) => {
  const [open, setOpen] = useState(prepareInitState(faqArr));
  const { t } = useTranslation();

  const onOpen = (id) => {
    const foundElement = open.find((item) => item.id === id);
    if (foundElement) {
      foundElement.value = true;
    }
    setOpen([...open]);
  };
  const onClose = (id) => {
    const foundElement = open.find((item) => item.id === id);
    if (foundElement) {
      foundElement.value = false;
    }
    setOpen([...open]);
  };

  const faqTitle = (itemTitle, id) => {
    const element = open.find((item) => item.id === id);

    return (
      <AccordionItemHeader>
        <AccordionItemTitle>
          {t(itemTitle)}
        </AccordionItemTitle>
        <img src={element.value ? arrowUp : arrowDown} alt="" />
      </AccordionItemHeader>
    );
  };

  return (
    <StyledContainer>
      <Flex direction="column" align="center">
        <BlockTitle size="28px" weight="700" align="center" marginBottom="32px">
          {t(title)}
        </BlockTitle>
        <AccordionsWrapper>
          {faqArr.map((item) => (
            <Collapsible
              onOpening={() => onOpen(item.id)}
              onClosing={() => onClose(item.id)}
              trigger={faqTitle(item.title, item.id)}
              key={`faq-question-${item.id}`}
            >
              <AccordionItemContent>
                {t(item.content)}
              </AccordionItemContent>
            </Collapsible>
          ))}
        </AccordionsWrapper>
      </Flex>
    </StyledContainer>
  );
};
