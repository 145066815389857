import React from 'react';
import styled from 'styled-components';

const StyledBlockTitle = styled.h3`
  color: ${(props) => props.color || props.theme.colors?.mainColor};
  margin-bottom: ${(props) => props.marginBottom || '16px'};
  font-size: ${(props) => props.size || '24px'};
  font-weight: ${(props) => props.weight || 600};
  text-align: ${(props) => props.align || 'left'};
  line-height: ${(props) => props.lineHeight || 'normal'};
  max-width: ${(props) => props.width};
  white-space: pre-line;

  @media (max-width: 768px) {
    font-size: 22px;
    max-width: unset;
    text-align: flex-start ;
  }
`;

export const BlockTitle = (props) => <StyledBlockTitle {...props} />;
