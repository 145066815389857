import i18n from 'i18n';
import React, { useEffect } from 'react';

export const TrustPilotWidget = () => {
  const language = i18n.language === 'en' ? 'en' : 'se';
  const ref = React.useRef();
  useEffect(() => {
    if (window.Trustpilot) {
      if (ref.current) {
        window.Trustpilot.loadFromElement(ref.current, true);
      }
    }
  }, []);

  return (
    <div
      style={{ minHeight: '24px' }}
      ref={ref}
      className="trustpilot-widget"
      data-locale={language === 'en' ? 'en-US' : 'sv-SE'}
      data-template-id="5419b6a8b0d04a076446a9ad"
      data-businessunit-id="641b005932ef9666402d1470"
      data-style-height="24px"
      data-style-width="100%"
      data-theme="light"
      data-min-review-count="10"
      data-without-reviews-preferred-string-id="1"
    >
      <a href="https://www.trustpilot.com/review/financed.se" style={{ opacity: 0 }} target="_blank" rel="noopener noreferrer">Trustpilot</a>
    </div>
  );
};
