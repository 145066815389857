import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: relative;
  max-width: 428px;

   @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledLink = styled(NavLink)`
  font-family: 'inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors?.mainColor};
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }

  &.active {
    font-weight: 700;
  }
  :hover{
    color:${(props) => props.theme.colors?.hoverColor};
    transition: all 0.3s;
  }
`;