import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    overflow-x: hidden;
    min-width: 320px;
    font-weight: 400;
    font-family: 'inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #272D4E
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  a {
    display: inline-block;
    text-decoration: none;
    color: #272D4E;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #272D4E
  }

  h1, h2, h3, h4, h5, h6, span {
    color: #272D4E
  }
  
  span {
    display: inline-block;
  }
`;
