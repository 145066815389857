import styled from 'styled-components';

export const StyledLanguageContainer = styled.div`
  position: relative;  
  
  &.opened {
    .secondLanguage {
      opacity: 1;
      visibility: visible;
    }
  }

  .secondLanguage {
    position: absolute;
    top: 44px;
    padding-top: 10px;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    z-index: 1001;
  }  
`;
