import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';

import { Input } from 'UI/common/components/Input/Input';
import { useGetTranslatedData } from 'utils/contentData';
import { Button } from 'UI/common/components/Button';
import { BlockTitle } from 'UI/common/components/Titles/BlockTitle';
import { ThankYouLayout } from 'UI/common/components/ThankYouLayout/ThankYouLayout';
import { phoneRegExp } from 'constants/helpers';
import {
  BASE_API_URL, BASE_FETCH_SETTINGS, CONTACT_US_URL,
} from 'constants/apiConstants';
import {
  StyledContainer, StyledContent, StyledTextarea, WrapperRecaptcha,
} from './styled';

export const FormContactUs = () => {
  const [showThankYou, setShowThankYou] = useState(false);
  const [verified, setVerified] = useState(false);
  const data = useGetTranslatedData();
  const { i18n, t } = useTranslation();
  const nameRegExp = /^\D*$/;

  const { post: postCaptcha, response: responseCaptcha } = useFetch('');

  const handleChangeCaptcha = async (value) => {
    if (value) {
      const captchaRes = await postCaptcha('captcha', { value });
      if (responseCaptcha.ok && captchaRes) setVerified(true);
    }
  };

  const captchaLang = i18n.language === 'en' ? 'en' : 'sv';

  const {
    post, response, cache,
  } = useFetch(BASE_API_URL, BASE_FETCH_SETTINGS);

  const validationSchema = Yup.object().shape({
    userName: Yup.string()
      .required(t('inputErrorRequired'))
      .matches(nameRegExp, t('inputErrorName'))
      .min(2, t('inputErrorShort'))
      .max(30, t('inputErrorLong'))
      .matches(/^\s*\S.*$/, t('inputErrorSpaces')),
    email: Yup.string()
      .email(t('inputErrorEmail'))
      .required(t('inputErrorRequired')),
    phoneNumber: Yup.string().matches(phoneRegExp, t('inputErrorPhone')).required(t('inputErrorRequired')),
    userQuestion: Yup.string()
      .min(6, t('inputErrorShort'))
      .max(1000, t('inputErrorLong'))
      .matches(/^\s*\S[\s\S]*$/, t('inputErrorSpaces'))
      .required(t('inputErrorRequired')),
  });

  const initialValues = {
    userName: '',
    email: '',
    userQuestion: '',
    phoneNumber: '',
  };

  const handlePostForm = async ({
    userName, email, userQuestion, phoneNumber,
  }, { resetForm }) => {
    const emailLang = i18n.language === 'en' ? 'en' : 'se';

    const newVal = {
      userName,
      email,
      phoneNumber,
      userQuestion,
      emailLang,
    };

    await post(CONTACT_US_URL, newVal);

    if (response.ok) {
      resetForm();
      setShowThankYou(true);
    } else {
      cache.clear();
    }
  };
  if (showThankYou) {
    return <ThankYouLayout />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur
      onSubmit={handlePostForm}
    >
      {({
        values, errors, isValid, touched, dirty, handleChange, handleBlur, handleSubmit,
      }) => (
        <StyledContainer>
          <StyledContent>
            <BlockTitle size="18px" marginBottom="32px">
              {t('sendYouQuestion')}
            </BlockTitle>
            {data.contactUs.form.map((item) => (
              <Input
                key={`key-${item.name}`}
                data={item}
                name={item.name}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values[item.name]}
                touched={touched[item.name]}
                errors={errors[item.name]}
                marginBottom="24px"
                maxWidth="100%"
              />
            ))}
            <StyledTextarea
              placeholder={t('yourQuestion')}
              name="userQuestion"
              className={
                errors.userQuestion && touched.userQuestion ? 'input-error' : null
              }
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.userQuestion}
            />
            {touched.userQuestion && errors.userQuestion && <p className="text-area-error">{errors.userQuestion}</p>}
            {verified
              ? (
                <Button
                  formButton
                  type="submit"
                  onClick={handleSubmit}
                  className={!(dirty && isValid) && 'disabled-btn'}
                  disabled={!(dirty && isValid)}
                >
                  {t('submitQuestion')}
                </Button>
              ) : (
                <WrapperRecaptcha>
                  <ReCAPTCHA
                    sitekey={process.env.RAZZLE_RECAPTCHA_PUBLIC_KEY}
                    hl={captchaLang}
                    onChange={handleChangeCaptcha}
                  />
                </WrapperRecaptcha>
              )}
          </StyledContent>
        </StyledContainer>
      )}
    </Formik>
  );
};
