import styled from 'styled-components'

export const StyledRangeTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;

  .title {
    font-weight: 500;
    font-size: 14px;
  }

  .sum {
    font-size: 16px;
    font-weight: 700;
  }

  .manual-value-input {
    position: relative;

    input {
      position: relative;
      width: 170px;
      height: 34px;
      border: 1px solid #e9f1ff;
      border-radius: 6px;
      text-align: right;
      padding-right: 50px;
      font-weight: 700;
      font-size: 16px;
      font-family: 'inter', sans-serif;
      color: ${(props) => props.theme.colors?.mainColor};

      &:focus {
        outline: none;
      }

      &.input-error {
        border: 1px solid #eb3235;
      }
    }

    img {
      position: absolute;
      top: 12.5px;
      left: 10px;
    }

    span {
      position: absolute;
      top: 7.5px;
      right: 16px;
      font-weight: 700;
      font-size: 16px;
      font-family: 'inter', sans-serif;
    }

    .error {
      position: absolute;
      bottom: -18px;
      left: 0;
      font-size: 12px;
      color: #eb3235;
    }
  }
`

export const StyledRangeContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 33px;
`
