import {
  LARGE_LOAN_TERMS, LARGE_LOAN_MAX_AMOUNT, LOAN_AMOUNT_LIMITS, LOAN_TERMS,
} from 'constants/helpers';
import i18next from 'i18next';

const SUM_RANGE_STEP_1 = 10000;
const SUM_RANGE_STEP_2 = 50000;
const SUM_RANGE_STEP_3 = 500000;

export const LogModule = (opts) => {
  const minpos = opts?.minpos || LOAN_AMOUNT_LIMITS.min;
  const maxpos = opts?.maxpos || LARGE_LOAN_MAX_AMOUNT;
  const scale = (Math.log(maxpos) - Math.log(minpos)) / (maxpos - minpos);
  const value = (position) => Math.exp((position - minpos) * scale + Math.log(minpos));
  const position = (val) => (minpos + (Math.log(val) - Math.log(minpos)) / scale);
  return { value, position };
};

export const amountRangeStep = (value) => {
  if (value < 100000) return SUM_RANGE_STEP_1;
  if (value < 1000000) return SUM_RANGE_STEP_2;
  return SUM_RANGE_STEP_3;
};

export const termFormatter = (monthsCount) => {
  const years = Math.floor(monthsCount / 12);
  return `${years} ${i18next.t('year', { count: years })}`;
};

export const loanLarge = (amount) => amount > LOAN_AMOUNT_LIMITS.max;

export const defineTermBoundaries = (amount) => ({
  min: loanLarge(amount) ? LARGE_LOAN_TERMS.min : LOAN_TERMS.min,
  max: loanLarge(amount) ? LARGE_LOAN_TERMS.max : LOAN_TERMS.max,
});
