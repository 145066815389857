import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import logo from 'UI/assets/images/logo.svg';
import { Flex } from 'UI/common/components/Flex';
import { Social } from 'UI/common/components/Social/Social';
import {
  StyledBackground,
  StyledContainer,
  StyledContent,
  StyledLogo,
  StyledLink,
  FirstTwoBlockWrapper,
  FirstColumn,
  FirstColumnMob,
  SecondColumn,
  SecondColumnMob,
  ThirdColumn,
  SocialMobWrapper,
} from './styled';

export const Footer = () => {
  const { i18n, t } = useTranslation();
  const localePrefix = useMemo(() => (i18n.language === 'en' ? 'en/' : ''), [i18n.language]);

  return (
    <StyledBackground>
      <StyledContainer>
        <StyledContent className="desktop">
          <a href="/">
            <StyledLogo className="logo" src={logo} alt="logo" />
          </a>
          <Flex justify="space-between" align="baseline">
            <FirstTwoBlockWrapper>
              <Flex justify="space-between" align="baseline">
                <FirstColumn>
                  <span>Org.nr: 559269-4904</span>
                  <div className="address">
                    <span>Financed Sweden AB</span>
                    <span>Box 7346</span>
                    <span>103 90 Stockholm</span>
                  </div>
                  <a className="tel link" href="tel:0843733145">
                    Tel:
                    <span>08-43733145</span>
                  </a>
                  <a
                    className="mail link"
                    href="mailto:info@financed.se"
                  >
                    Mail:
                    {' '}
                    <span>info@financed.se</span>
                  </a>
                </FirstColumn>
                <SecondColumn>
                  <StyledLink to={`${localePrefix}${t('urlNameBusiness')}`}>{t('navBusinessLoan')}</StyledLink>
                  <StyledLink to={`${localePrefix}factoring`}>{t('navFactoring')}</StyledLink>
                  <StyledLink to={`${localePrefix}${t('urlNameAbout')}`}>{t('navAboutUs')}</StyledLink>
                  <StyledLink to={`${localePrefix}${t('urlNameContact')}`}>{t('navContactUs')}</StyledLink>
                </SecondColumn>
              </Flex>
            </FirstTwoBlockWrapper>
            <ThirdColumn>
              <Social />
              <div className="copyright">
                <span>{`Copyright © ${new Date().getFullYear()}.`}</span>
                <span>All rights reserved.</span>
              </div>
            </ThirdColumn>
          </Flex>
        </StyledContent>
        <StyledContent className="mobile">
          <a href="/">
            <StyledLogo className="logo" src={logo} alt="logo" />
          </a>
          <Flex justify="space-between">
            <FirstColumnMob>
              <p>
                Org.nr:
                {' '}
                <br />
                559269-4904
              </p>
              <p>
                Financed Sweden AB
                {' '}
                <br />
                Box 7346
                {' '}
                <br />
                103 90 Stockholm
              </p>
            </FirstColumnMob>
            <SecondColumnMob>
              <p className="phone">
                Tel:
                <br />
                <a href="tel:0843733145">
                  08-43733145
                </a>
              </p>
              <p className="mail">
                Mail:
                <br />
                <a href="mailto:info@financed.se">
                  info@financed.se
                </a>
              </p>
            </SecondColumnMob>
          </Flex>
          <SocialMobWrapper>
            <Social />
          </SocialMobWrapper>
          <p className="copyright">{`Copyright © ${new Date().getFullYear()}. All rights reserved.`}</p>
        </StyledContent>
      </StyledContainer>
    </StyledBackground>
  );
};
