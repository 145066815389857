import React, { useEffect, useMemo, useState } from 'react';

import { Header } from 'UI/components/sections/Header/Header';
import { Footer } from 'UI/components/sections/Footer/Footer';
import { EllipseBlock } from 'UI/common/components/EllipseBlock';
import { usePathMatch } from 'customHooks/usePathMatch';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  GradientWrapper, StyledBlock, StyledContainer, StyledExpandedContainer, StyledMain,
} from './styled';
import CookieConsentComponent from '../../../components/CookieConsent';
import { ModalWindow } from '../../common/components/Modal/Modal';
import { Policy } from '../../../components/Policy/Policy';

export const MainLayout = ({ children }) => {
  const isLoanOrder = usePathMatch('en/loan-order-form', 'bestallningsblankett-for-lan');
  const isErrorPage = usePathMatch('en/404', '404');
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const isTermsAndPolicy = useMemo(() => search === '?terms-conditions', [search]);
  const [showModalWindow, setShowModalWindow] = useState(false);

  useEffect(() => {
    if (isTermsAndPolicy) setShowModalWindow(true);
  }, [isTermsAndPolicy]);
  const closeModal = () => {
    setShowModalWindow(false);
    navigate(pathname);
  };
  return (
    <StyledBlock>
      <GradientWrapper
        isLoanOrder={isLoanOrder}
        isErrorPage={isErrorPage}
      />
      <StyledExpandedContainer>
        <EllipseBlock />
        <StyledContainer>
          <header><Header /></header>
        </StyledContainer>
      </StyledExpandedContainer>
      <StyledMain isLoanOrder={isLoanOrder}>
        {children}
      </StyledMain>
      <footer><Footer /></footer>
      <CookieConsentComponent />
      {showModalWindow && (
        <ModalWindow closeAction={closeModal} showIcon>
          <Policy />
        </ModalWindow>
      )}
    </StyledBlock>
  );
};
