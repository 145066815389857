import styled, {keyframes} from 'styled-components';

const flicker = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`

export const StyledWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center; 
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #E5EFFF 0%, rgba(229, 239, 255, 0.262661) 83.7%, rgba(229, 239, 255, 0) 100%);

  img {
    width: 60vw;
    height: 20vw;
    opacity: 1;
    animation: ${flicker} 1s linear infinite;
  }
`