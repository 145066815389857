import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useGetTranslatedData } from 'utils/contentData';
import { localStorage } from 'utils/server.connectors';
import { altUrlNameMaper } from 'constants/urlName';
import { Flag } from './Flag/Flag';
import { StyledLanguageContainer } from './styled';

const dummy = () => {};

export const Language = () => {
  const data = useGetTranslatedData();
  const { pathname } = useLocation();
  const [displayLangOpts, setDisplayLangOpts] = useState(false);
  const { i18n } = useTranslation();

  const toggleDisplayOpts = (e) => {
    e.stopPropagation();
    setDisplayLangOpts(!displayLangOpts);
  };

  useEffect(() => {
    if (document) {
      const bodyEl = document.querySelector('body');
      bodyEl.addEventListener('click', () => setDisplayLangOpts(false));
    }
  }, []);

  const changeLanguage = (lng) => {
    localStorage.setItem('i18nextLng', lng);
    let newPath;
    if (i18n.language === 'en') {
      newPath = `/${altUrlNameMaper[pathname.replace('/en/', '')] ?? ''}`;
    } else {
      newPath = `/en/${altUrlNameMaper[pathname.replace('/', '')] ?? ''}`;
    }
    if (newPath === '/en/') {
      newPath = '/en';
    }
    window.location.replace(newPath || '/');
  };

  return (
    <StyledLanguageContainer onClick={toggleDisplayOpts} className={displayLangOpts ? 'opened' : ''}>
      <Flag data={data.language[i18n.language]} onLangSelect={dummy} />
      <div className="secondLanguage">
        <Flag data={data.language[i18n.language === 'en' ? 'sv' : 'en']} onLangSelect={changeLanguage} />
      </div>
    </StyledLanguageContainer>
  );
};
