import styled from 'styled-components';

import faqGradient from 'UI/assets/images/mapBg.png';

export const StyledContainer = styled.div`
  position: relative;
  max-width: 1120px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;

  @media (max-width: 768px) {    
    padding: 0;
    
   > div {
      &:first-child {
        flex-direction: column;   
      }
    }
  }
`;

export const StyledFaqContainer = styled.div`
  background-image: url(${faqGradient});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 182px;

  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 84px;
  }
`;
