import React from 'react';

import { Flex } from 'UI/common/components/Flex';
import { LeftSide } from './LeftSide/LeftSide';
import { RightSide } from './RightSide/RightSide';
import { StyledContainer } from './styled';

export const TwoSectionBlock = ({ pictureRight, data }) => (
  <StyledContainer pictureRight={pictureRight}>
    {
      pictureRight
        ? (
          <Flex>
            <LeftSide data={data.twoSectionBlock.rightSideImage} pictureRight />
            <RightSide pictureRight data={data.twoSectionBlock.rightSideImage} />
          </Flex>
        )
        : (
          <Flex>
            <RightSide pictureLeft data={data.twoSectionBlock.leftSideImage} />
            <LeftSide leftSideRight data={data.twoSectionBlock.leftSideImage} />
          </Flex>
        )
    }
  </StyledContainer>
);
