import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import ReCAPTCHA from 'react-google-recaptcha';

import { Input } from 'UI/common/components/Input/Input';
import { useGetTranslatedData } from 'utils/contentData';
import { Button } from 'UI/common/components/Button';
import { Flex } from 'UI/common/components/Flex';
import { CONVERSION_TRACK_CODE, phoneRegExp } from 'constants/helpers';
import { partnerStore } from 'store/partner';
import { Text } from 'UI/common/components/Text';
import { ThankYouLayout } from 'UI/common/components/ThankYouLayout/ThankYouLayout';
import {
  BASE_API_URL, BASE_FETCH_SETTINGS, FACTORING_URL,
} from 'constants/apiConstants';
import {
  StyledContainer, StyledContent, StyledPolicy, WrapperRecaptcha,
} from './styled';

const initialValues = {
  companyRegistrationNumber: '',
  phoneNumber: '',
  email: '',
  productsDescription: '',
  invoicesNum: '',
  averageInvoice: '',
};

export const Form = () => {
  const data = useGetTranslatedData();
  const [agree, setAgree] = useState(false);
  const [partner] = useRecoilState(partnerStore);
  const [showThankYou, setShowThankYou] = useState(false);
  const [verified, setVerified] = useState(false);
  const { i18n, t } = useTranslation();

  const { post: postCaptcha, response: responseCaptcha } = useFetch('');

  const handleChangeCaptcha = async (value) => {
    if (value) {
      const captchaRes = await postCaptcha('captcha', { value });
      if (responseCaptcha.ok && captchaRes) setVerified(true);
    }
  };

  const captchaLang = i18n.language === 'en' ? 'en' : 'sv';

  const { post, response, cache } = useFetch(BASE_API_URL, BASE_FETCH_SETTINGS);

  const validationSchema = Yup.object().shape({
    companyRegistrationNumber: Yup.string().matches(/^[0-9]{10}$/, t('inputErrorExactlyTen')).required(t('inputErrorRequired')),
    phoneNumber: Yup.string().matches(phoneRegExp, t('inputErrorPhone')).required(t('inputErrorRequired')),
    email: Yup.string().email(t('inputErrorEmail')).required(t('inputErrorRequired')),
    productsDescription: Yup.string().max(100, t('inputErrorLong')).required(t('inputErrorRequired')),
    invoicesNum: Yup.string().max(100, t('inputErrorLong')).required(t('inputErrorRequired')),
    averageInvoice: Yup.string().max(100, t('inputErrorLong')).required(t('inputErrorRequired')),
  });

  const postFactoringForm = async ({
    companyRegistrationNumber, phoneNumber, email, productsDescription, invoicesNum, averageInvoice,
  }, { resetForm }) => {
    const emailLang = i18n.language === 'en' ? 'en' : 'se';

    const newVal = {
      companyRegistrationNumber,
      phoneNumber,
      email,
      productsDescription,
      invoicesNum,
      averageInvoice,
      termsAccepted: true,
      emailLang,
      partner,
    };

    await post(FACTORING_URL, newVal);

    if (response.ok) {
      resetForm();
      setAgree(false);
      setShowThankYou(true);
    } else {
      cache.clear();
    }

    // gtag_report_conversion added like script in server.js
    if (gtag_report_conversion) {
      gtag_report_conversion(true, CONVERSION_TRACK_CODE.Factoring);
    }
  };

  if (showThankYou) {
    return <ThankYouLayout />;
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur
      onSubmit={postFactoringForm}
    >
      {({
        values, errors, isValid, touched, dirty, handleChange, handleBlur, handleSubmit,
      }) => (
        <StyledContainer>
          <StyledContent>
            {data.factoring.form.map((item) => (
              <Input
                key={`key-${item.name}`}
                data={item}
                name={item.name}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values[item.name]}
                touched={touched[item.name]}
                errors={errors[item.name]}
                marginBottom="24px"
                maxWidth="none"
                padding="0 24px 0 24px"
                isFactoringTextInput={item.type === 'text'}
              />
            ))}
            <StyledPolicy>
              <Flex>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="container">
                  <Text size="14px" lineHeight="22px">
                    {t('agreeFactoringForm')}
                  </Text>
                  <input type="checkbox" checked={agree} onChange={() => setAgree(!agree)} />
                  <span className="checkmark" />
                </label>
              </Flex>
            </StyledPolicy>
            {verified ? (
              <Button
                formButton
                type="submit"
                onClick={handleSubmit}
                className={!(dirty && isValid) && 'disabled-btn'}
                disabled={!(dirty && isValid && agree)}
              >
                {t('goToOfferBtn')}
              </Button>
            ) : (
              <WrapperRecaptcha>
                <ReCAPTCHA
                  sitekey={process.env.RAZZLE_RECAPTCHA_PUBLIC_KEY}
                  hl={captchaLang}
                  onChange={handleChangeCaptcha}
                />
              </WrapperRecaptcha>
            )}
          </StyledContent>
        </StyledContainer>
      )}
    </Formik>
  );
};
