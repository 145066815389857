import styled from 'styled-components';

export const FirstStepItem = styled.div`
  padding-top: 45px;
  width: clamp(290px, 100%, 403px);

  @media (max-width: 768px) {
    padding-top: 0;
  }
`;

export const FirstStepHeader = styled.div`
  padding-top: 15px;
  padding-bottom: 25px;
  gap: 24px;
  @supports not (aspect-ratio: 1 / 1){
    img { margin-bottom: 24px; }
  }
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    display: block;
    margin: 0;
    padding: 0;
    max-width: 100%;
    height: auto;
  }
`;

export const FirstStepHeaderText = styled.h3`
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: ${(props) => props.theme.colors?.mainColor};

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const FirstStepBody = styled.div`
  display: flex;
  flex-direction: column;  
  align-items: center;
  
  input {
    background: ${(props) => props.theme.colors?.buttonColor}; 
    padding-right: 55px;
   
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
  }

  @media (max-width: 425px) {
    input {
      padding-right: 0;
    }
  }
`;

export const FirstStepAction = styled.div`
  padding-top: 24px;
`;

export const LoadingInformation = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const StyledLink = styled.a`
  @media (any-hover: hover){
    &:hover{
      text-decoration: underline;
    }
  }
`;
