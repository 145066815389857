import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: relative;
  flex: 0 1 65%;
  background: #fff;
  border-radius: 20px;
  margin-top: 104px;
  
  @media (max-width: 768px) {
    margin-top: 20px;    
  }
`;

export const StyledContent = styled.div`
  padding: 32px 32px 24px 32px;
  margin-bottom: 16px;  

  .checkbox {
    margin-right: 10px;
  }
  
  div {
    &:nth-child(4) {
      input {
        height: 76px;       
        padding-top: 14px;
        padding-bottom: 34px;
        padding-right: 40px;        

        ::placeholder,
        ::-webkit-input-placeholder {
          white-space: pre-line;
          line-height: normal !important;
          vertical-align: middle;          
          margin: auto 0;
        }

        :-ms-input-placeholder {
          white-space: pre-line;
          line-height: normal !important;
          vertical-align: middle;         
          margin: auto 0;
        }
      }

      .error {
        top: 79px;
      }
    }
  }

  @media (max-width: 820px) {
    padding: 24px 16px;
    div {     
      &:nth-child(5), &:nth-child(6) {
        input {
          height: 76px;         
          padding-top: 14px;
          padding-bottom: 27px;

          ::placeholder,
          ::-webkit-input-placeholder {
            white-space: pre-line;
            line-height: normal !important;
            vertical-align: middle;      
            margin: auto 0;
          }

          :-ms-input-placeholder {
            white-space: pre-line;
            line-height: normal !important;
            vertical-align: middle;           
            margin: auto 0;
          }
        }
        .error {
          top: 79px;
        }
      }     
      }
    } 
  `;

export const StyledPolicy = styled.div`
  margin-bottom: 24px;
  /* Customize the label (the container) */

  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 3px solid #815EFD;
    border-radius: 6px;
  }

  /* On mouse-over, add a grey background color */

  //.container:hover input ~ .checkmark {
  //  background-color: #ccc;
  //}

  /* When the checkbox is checked, add a blue background */

  .container input:checked ~ .checkmark {
    background-color: #815EFD;
    border: none;
  }

  /* Create the checkmark/indicator (hidden when not checked) */

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */

  .container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-radius: 1px;
  }
`;

export const WrapperRecaptcha = styled.div`
  display: flex;
  justify-content: center;
`;
