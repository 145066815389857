import styled from 'styled-components';

import rectangleBackground from 'UI/assets/images/rectangleBackground.jpg';
import rectangleMobBackground from 'UI/assets/images/rectangleMobBackground.png';
import rectangleLoanMobBackground from 'UI/assets/images/rectangleLoanMobBackground.png';

export const StyledBackground = styled.div`
  background-image: url(${rectangleBackground});
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

   @media (max-width: 768px) {
     margin-top: ${(props) => (props.loan || props.factoring ? '16px' : '60px')};
     background-image:url(${(props) => (props.loan || props.factoring ? rectangleLoanMobBackground : rectangleMobBackground)});
     height: ${(props) => (props.loan || props.factoring) && '455px'};
     overflow: ${(props) => ((props.loan || props.factoring) && 'hidden')};
   } 
`;

export const StyledContainer = styled.div`
  max-width: 1120px;
  padding: 0 15px;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 1090px;
    padding: 0;
  }
`;

export const StyledContent = styled.div`
  padding: ${(props) => props.padding || '110px 0'};

  @media (max-width: 768px) {
    padding: 0;
    
    div {
      flex-direction: column;
    }
  }
`;

export const LeftSide = styled.div`
  max-width: ${(props) => props.maxWidth || '600px'};
  padding-bottom: ${(props) => props.paddingBottom || 0};
  margin-right: ${(props) => props.marginRight || 0};

  @media (max-width: 768px) {
    order: ${(props) => (props.loan ? '0' : '1')};
    padding: 0 16px;
    margin-right: 0;
    padding-top: 44px;

    span {
      margin-bottom: 44px;
    }

    div {
      align-items: flex-start;
       
      input {
        margin-bottom: 24px;
      }

      img {
        top: 35%;
      }
    }
  }
`;

export const RightSide = styled.div`
  flex: 0 1 auto; 
  
   @media (max-width: 768px) {
     
    order:${(props) => props.factoring && '2'} ;
     img {
       position: relative;
       top: ${(props) => (props.loan || props.factoring ? '-58px' : '-34px')};
       left: ${(props) => ((props.loan || props.factoring) && '80px')};
       width: ${(props) => (props.loan || props.factoring ? '181px' : 'initial')}; 
       height: ${(props) => (props.loan || props.factoring ? '210px' : 'initial')};       
     }
   }
`;
