import styled from 'styled-components';
import faqGradient from 'UI/assets/images/mapBg.png';

export const StyledContainer = styled.div`
  max-width: 1120px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;

  @media (max-width: 820px) {    
    padding: 0 16px;
    
   > div {
      &:first-child {
        position: relative;
        top: 0;
        left: 0;
        max-width: 350px;
        margin: 40px auto 0;
          img {
            width: 349px;
            height: 199px;
        }
      }
      &:nth-child(2) {
        h1 {
          max-width: 100%;
          margin-bottom: 14px;
          white-space: unset;
          text-align: center;

          @media (max-width: 768px) {
            text-align: left;
          }
        }
        div {
          &:last-child {
            max-width: 100%;
          }
        }
      }
      &:nth-child(3) {
        margin-top: 50px;
      }
      &:nth-child(4) {
        margin-top: 57px;
      }
      &:last-child {
        margin-top: 30px;       
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const PeopleBlock = styled.div`
  position: absolute;
  top: 147px;
  right: -40px;

  img {
    width: 707px;
    height: 402px;
  }

  @media (max-width: 1180px) {
    right: -115px;
    top: 135px;
  }

  @media (max-width: 1024px) {
    right: -150px;
  }
`;

export const StyledFaqContainer = styled.div`
  background-image: url(${faqGradient});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 768px) {
    padding-top: 60px;
  }
`;

export const StyledContainerPartners = styled.div`
  max-width: 1120px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0;
  }
`;
