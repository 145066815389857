import { atom } from 'recoil';// eslint-disable-next-line no-unused-vars
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const companiesStore = atom({
  key: 'companiesStore',
  default: [],
  effects_UNSTABLE: [persistAtom],
});
