import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: relative;
  flex: 0 1 65%;
  background: #fff;
  border-radius: 20px;
  margin-top: 104px;
  max-width: 467px;

  @media (max-width: 768px) {
    margin: 16px auto 0;
    min-width: 375px;
  }

  @media (max-width: 375px) {
    min-width: unset;
  }
`;

export const StyledContent = styled.div`
  padding: 27px 32px 24px 32px;
  margin-bottom: 16px;

  .checkbox {
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    padding: 28px 16px 24px;
  }
`;

export const StyledPolicy = styled.div`
  margin-bottom: 32px;
  /* Customize the label (the container) */

  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 3px solid #815EFD;
    border-radius: 6px;
  }

  /* On mouse-over, add a grey background color */

  //.container:hover input ~ .checkmark {
  //  background-color: #ccc;
  //}

  /* When the checkbox is checked, add a blue background */

  .container input:checked ~ .checkmark {
    background-color: #815EFD;
    border: none;
  }

  /* Create the checkmark/indicator (hidden when not checked) */

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */

  .container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-radius: 1px;
  }
`;

export const StyledText = styled.span`
  font-size: 14px;
  color: #607B9B;

  a {
    text-decoration: underline;
    color: #607B9B;
  }
`;

export const StyledSpan = styled.span`
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #272D4E;
  margin-top: 24px;
`;

export const StyledRange = styled.input`
  display: block;

  /* The slider itself */
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 8px; /* Specified height */
  background: #E9F1FF;
  border-radius: 6px;
  outline: none; /* Remove outline */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;


  ::-ms-fill-lower {
    background: #607B9B;
    border-radius: 10px;
  }

  ::-ms-fill-upper {
    background: #E9F1FF;
    border-radius: 10px;
  }

  :focus::-ms-fill-lower {
    background: #607B9B;
  }

  :focus::-ms-fill-upper {
    background: #E9F1FF;
  }

  ::-webkit-slider-container {
    //background-color: white;
  }

  ::-webkit-slider-runnable-track {
    border-radius: 20px;
    min-inline-size: 0;
    align-self: center;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: transparent;
    -webkit-user-modify: read-only !important;
    flex: 1 1 0;
    :before {
      content: '';
      height: 8px;
      background-color: #607B9B;
      border-radius: 20px;
      width: 100px;
    }
    :after {
      content: '';
      height: 8px;
      border-radius: 20px;
      color: #E9F1FF;
      width: 10px;
    }
    
  }

  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */

  ::-webkit-slider-thumb {
    box-sizing: border-box;
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 20px; /* Set a specific slider handle width */
    height: 20px; /* Slider handle height */
    background: #815EFD; /* Green background */
    border-radius: 50%;
    cursor: pointer; /* Cursor on hover */
    border: 2px solid white;
  }

  ::-moz-range-thumb {
    box-sizing: border-box;
    border: 2px solid white;
    width: 20px; /* Set a specific slider handle width */
    height: 20px; /* Slider handle height */
    background: #815EFD; /* Green background */
    border-radius: 50%;
    cursor: pointer; /* Cursor on hover */
  }

  &[type="range"] {
    -webkit-appearance: none;
    margin-right: 15px;
    height: 8px;
    background: #E9F1FF;
    border-radius: 20px;
    background-image: linear-gradient(#815EFD, #815EFD);
    background-size: 70% 100%;
    background-repeat: no-repeat;
  }

  /* Input Thumb */
  &[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    background: #815EFD;
    cursor: pointer;
    transition: background .3s ease-in-out;
  }

  &[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    background: #815EFD;
    cursor: pointer;
    transition: background .3s ease-in-out;
  }

  &[type="range"]::-ms-thumb {
    -webkit-appearance: none;
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    background: #815EFD;
    cursor: ew-resize;
    transition: background .3s ease-in-out;
  }

  &[type="range"]::-webkit-slider-thumb:hover {
    background: #815EFD;
  }

  &[type="range"]::-moz-range-thumb:hover {
    background: #815EFD;
  }

  &[type="range"]::-ms-thumb:hover {
    background: #815EFD;
  }

  /* Input Track */
  &[type="range"]::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    //background: #E9F1FF;
    //color: #13bba4
  }

  &[type="range"]::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: #E9F1FF;
  }

  &[type="range"]::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: #E9F1FF;
  }
`;
