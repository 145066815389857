import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  Routes, Route, Navigate, useSearchParams,
} from 'react-router-dom';

import { Home } from 'UI/components/pages/Home/Home';
import { AboutUs } from 'UI/components/pages/AboutUs/AboutUs';
import { ContactUs } from 'UI/components/pages/ContactUs/ContactUs';
import { Factoring } from 'UI/components/pages/Factoring/Factoring';
import { BusinessLoan } from 'UI/components/pages/BusinessLoan/BusinessLoan';
import { NotFound } from 'UI/components/pages/404/404';
import { LoanStepper } from 'UI/components/pages/LoanStepper/LoanStepper';
import { partnerStore } from 'store/partner';

export const AppRoutes = () => {
  const { i18n, t } = useTranslation();
  const [, setPartnerState] = useRecoilState(partnerStore);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const targetPartner = searchParams.get('partner');
    if (targetPartner) {
      setPartnerState(targetPartner);
    }
  }, []);

  const routes = useMemo(() => ([
    {
      exact: false,
      path: `${i18n.language === 'en' ? 'en/' : ''}${t('urlNameAbout')}`,
      element: <AboutUs />,
      keyPrefix: 'route-about-',
    },
    {
      exact: false,
      path: `${i18n.language === 'en' ? 'en/' : ''}${t('urlNameContact')}`,
      element: <ContactUs />,
      keyPrefix: 'route-contact-',
    },
    {
      exact: false,
      path: `${i18n.language === 'en' ? 'en/' : ''}factoring`,
      element: <Factoring />,
      keyPrefix: 'route-factoring-',
    },
    {
      exact: false,
      path: `${i18n.language === 'en' ? 'en/' : ''}${t('urlNameBusiness')}`,
      element: <BusinessLoan />,
      keyPrefix: 'route-loan-',
    },
    {
      exact: false,
      path: `${i18n.language === 'en' ? 'en/' : ''}${t('urlNameLoanStepper')}`,
      element: <LoanStepper />,
      keyPrefix: 'route-stepper-',
    },
    {
      exact: true,
      path: `${i18n.language === 'en' ? 'en' : ''}`,
      element: <Home />,
      keyPrefix: 'route-home-',
    },
    {
      exact: true,
      path: '/',
      element: <Navigate to={`/${i18n.language === 'en' ? 'en' : ''}`} />,
      keyPrefix: 'route-redirect-home-',
    },
  ]), [i18n.language]);

  return (
    <Routes>
      {routes.map((item) => (
        <Route {...item} key={item.keyPrefix + i18n.language} />
      ))}
      <Route path="*" element={<NotFound />} status={404} />
    </Routes>
  );
};
