import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledLink } from './styled';

export const ContactMessage = () => {
  const { t } = useTranslation();
  return (
    <>
      <p>{t('contactMsg')}</p>
      <br />
      <StyledLink href="tel:0843733145">08-43733145</StyledLink>
      <br />
      <StyledLink href="mailto:info@financed.se">info@financed.se</StyledLink>
    </>
  );
};
