import React, { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useGetTranslatedData } from 'utils/contentData';
import { Faq } from 'UI/common/components/Faq/Faq';
import { TwoSectionBlock } from 'UI/common/components/TwoSectionBlock/TwoSectionBlock';
import { ThreeItemsBlock } from 'UI/common/components/ThreeItemsBlock/ThreeItemsBlock';
import { Banner } from 'UI/components/pages/Home/Banner/Banner';
import { Flex } from 'UI/common/components/Flex';
import { businessFaqData } from 'constants/FaqData';
import { threeItemsLoanData } from 'constants/ThreeItemsBlockData';
import { HelmetWrapper } from 'components/HelmetWrapper/HelmetWrapper';
import { fieldsInitial, fieldsStore } from 'store/forms';
import { companiesStore } from 'store/companies';
import { offersStore } from 'store/offers';
import i18n from 'i18n';
import { useNavigate } from 'react-router-dom';
import { useFetch } from 'use-http';
import { BASE_API_URL, BASE_FETCH_SETTINGS, INITIAL_OFFER_URL } from 'constants/apiConstants';
import { CONVERSION_TRACK_CODE, STEP } from 'constants/helpers';
import { partnerStore } from 'store/partner';
import { TrustPilotWidget } from 'UI/common/components/TrustWidget/TrustPilotWidget';
import { StyledContainer, StyledFaqContainer } from './styled';
import { Form } from './Form/Form';

export const BusinessLoan = () => {
  const data = useGetTranslatedData();
  const [formState, setFormState] = useRecoilState(fieldsStore);
  const setCompaniesState = useSetRecoilState(companiesStore);
  const [offerState, setOfferState] = useRecoilState(offersStore);

  const partner = useRecoilValue(partnerStore);
  const setOffersState = useSetRecoilState(offersStore);
  const navigate = useNavigate();
  const pathToLoanStepPage = useMemo(() => (i18n.language === 'en' ? '/en/loan-order-form' : '/bestallningsblankett-for-lan'), [i18n.language]);
  const emailLang = i18n.language === 'en' ? 'en' : 'se';

  const {
    post, response, loading,
  } = useFetch(BASE_API_URL, BASE_FETCH_SETTINGS);
  const proceedToNextStep = async (values, { resetForm }) => {
    const {
      amount,
      contactEmail,
      contactPhoneNumber, loanTermInMonths,
    } = values;
    const payload = {
      amount,
      loan_term_in_months: loanTermInMonths,
      contact_email: contactEmail,
      contact_phone_number: contactPhoneNumber,
      email_lang: emailLang,
      partner,
      passed_step: STEP.InitialOfferData,

    };
    if (gtag_report_conversion) {
      gtag_report_conversion(false, CONVERSION_TRACK_CODE.BusinessLoan);
    }
    await post(INITIAL_OFFER_URL, payload);

    if (response.ok) {
      const { offer_id: offerId } = response.data;
      setOfferState({ ...offerState, offerId });
      setFormState({
        ...formState, ...values, currentStep: 1,
      });
    }

    resetForm();
    navigate(pathToLoanStepPage);
  };
  useEffect(() => {
    setFormState(fieldsInitial);
    setOffersState({});
    setCompaniesState([]);
  }, []);

  return (
    <>
      <HelmetWrapper />
      <StyledContainer>
        <Flex gap="10px">
          <Flex direction="column" gap="10px">
            <Banner data={data.loan.banner} screen="Loan" />
            <TrustPilotWidget language={emailLang} />
          </Flex>

          <Form loading={loading} onNextStep={proceedToNextStep} />
        </Flex>
        <ThreeItemsBlock dataArray={threeItemsLoanData} />
        <TwoSectionBlock data={data.loan} pictureRight />
      </StyledContainer>
      <StyledFaqContainer>
        <Faq faqArr={businessFaqData} title="commonQuestionsBusiness" />
      </StyledFaqContainer>
    </>
  );
};
