import { BASE_API_URL, BASE_FETCH_SETTINGS, OFFERS_URL } from 'constants/apiConstants';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { fieldsStore } from 'store/forms';
import { offersStore } from 'store/offers';
import { userStore } from 'store/user';
import useFetch from 'use-http';
import { Form } from './Form/Form';

export const FinancialReport = () => {
  const [formState, setFormState] = useRecoilState(fieldsStore);
  const offerState = useRecoilValue(offersStore);
  const { ssn } = useRecoilValue(userStore);
  const { patch, response, loading } = useFetch(BASE_API_URL, BASE_FETCH_SETTINGS);
  const proceedToNextStep = ({ loanPurpose, companyBusiness, reportFiles }) => setFormState({
    ...formState,
    currentStep: 4,
    loanPurpose,
    companyBusiness,
    reportFilesNames: reportFiles?.map((file) => file.name) || [],
  });

  const hadleFormSubmit = async (values) => {
    const { loanPurpose, companyBusiness, reportFiles } = values;
    const formData = new FormData();

    formData.append('loan_purpose', loanPurpose);
    formData.append('company_business', companyBusiness);
    formData.append('ssn', ssn);

    if (reportFiles?.length) {
      reportFiles.forEach((file) => {
        formData.append('report_files', file);
      });
    }
    await patch(`${OFFERS_URL}${offerState.offerId}/credit_details/`, formData);

    if (response.ok) {
      proceedToNextStep(values);
    }
  };

  return (
    <Form onFormSubmit={hadleFormSubmit} loading={loading} />
  );
};
