import React, { useEffect, useState } from 'react';
import { InputRangeWrapper, StyledRange } from './styled';

export const RangeInput = ({
  value = 0, name, handleChange, minVal = 0, maxVal = 30000000, ...rest
}) => {
  const [bgWidth, setBgWidth] = useState(0);
  const calculateBgWidth = (val) => setBgWidth((((val - minVal) / (maxVal - minVal)) * 100));
  const handleRangeChange = (event) => {
    handleChange(name, +event.target.value);
  };
  useEffect(() => {
    calculateBgWidth(value);
  }, [value, maxVal]);

  return (
    <InputRangeWrapper>
      <StyledRange
        type="range"
        className="slider"
        min={minVal}
        max={maxVal}
        name={name}
        value={value}
        onChange={handleRangeChange}
        bgWidth={bgWidth}
        {...rest}
      />
    </InputRangeWrapper>
  );
};
