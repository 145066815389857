import React from 'react';
import Facebook from '../UI/assets/images/icons/facebook';
import LinkedIn from '../UI/assets/images/icons/linkedin';
import Instagram from '../UI/assets/images/icons/instagram';

export const socialData = [
  {
    href: 'https://www.facebook.com/Financed-Sweden-102827655659184',
    element: <Facebook className="social__icon" />,
  },
  {
    href: 'https://www.linkedin.com/company/financed-sweden',
    element: <LinkedIn className="social__icon" />,
  },
  {
    href: 'https://www.instagram.com/financed.se/',
    element: <Instagram className="social__icon" />,
  },
];
