import styled from 'styled-components';

export const StyledExpandedContainer = styled.div`
  position: relative;
  max-width: 1320px;
  margin: 0 auto;
`;

export const StyledContainer = styled.div`
  max-width: 1120px;
  padding: 0 15px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const StyledBlock = styled.div`
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors?.buttonColor};
  overflow-x: hidden;

  @media (max-width: 768px) {
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
  }


`;

export const StyledMain = styled.main`
  background: ${({ isLoanOrder, theme }) => isLoanOrder && theme.backgrounds?.gradientFull};
  height: ${({ isLoanOrder }) => isLoanOrder && 'fit-content'};

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const GradientWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ isLoanOrder, isErrorPage }) => ((isLoanOrder || isErrorPage) ? '800px' : '1050px')};
  background: ${({ theme }) => theme.backgrounds?.gradient};
`;
