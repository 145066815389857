import styled from 'styled-components';

export const StyledContainer = styled.div`
  margin-top: 80px;
  background: #fff;
  border-radius: 60px;

  &.loan {
    margin-top: 80px;   
  }

  @media (max-width: 768px) {
    margin: 80px 16px 0;
    border-radius: 20px;
    padding-bottom: 24px;
    
    &.loan {
    margin-top: 40px;
  }  
    
    div {
      div {
        flex-direction: column;
      }
    }
  }
`;

export const StyledContent = styled.div`
  padding: 44px 0;

  @media (max-width: 768px) {
    padding: 24px 0 0;
    border-radius: 20px;   
  }
`;
