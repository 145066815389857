import React from 'react';
import styled from 'styled-components';
import arrow from 'UI/assets/images/icons/arrowRight.svg';

const StyledButton = styled.button`
  position: relative;
  border: none;
  padding: 16px 46px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 6px;
  opacity: 1;
  color: ${(props) => props.color || props.theme.colors?.buttonColor};
  background: ${(props) => props.background || props.theme.backgrounds?.buttonBackground};
  margin: ${(props) => props.margin || '0'};
  z-index: 1;
  :hover{
    transition: all 0.3s;
    box-shadow: 0 14px 34px rgba(129, 94, 253, 0.4);
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  ${(props) => props.readMore && `
   color: ${({ color }) => color};
   background: #fff;
   border-width: 1px;
   border-style: solid;
   border-color: ${({ color }) => color};
     :hover{
    transition: all 0.3s;
    color: #fff;
    background: #815EFD;
      box-shadow: none;
  }
  `
}
  ${(props) => props.submit && `
    height: 48px;
    width: 180px;
    padding: 0 0;
      :hover{
    transition: all 0.3s;
    box-shadow: 0 14px 34px rgba(129, 94, 253, 0.4);
  }
  `
}
  ${(props) => props.formButton && `
    display: block;
    width: 100%;
    height:'100%';
      &:before {
    content: url(${arrow});
    position: absolute;
    top: 45%;
    transform: translateY(-45%);
    margin-right: 17px;
    right: 0;
    height: 20px;
    width: 20px;
      :hover{
    transition: all 0.3s;
    box-shadow: 0 14px 34px rgba(129, 94, 253, 0.4);
  }
  }
    `
}
`;

export const Button = (props) => <StyledButton {...props} />;
