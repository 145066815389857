import styled from 'styled-components';

export const SecondStepItem = styled.div`
  padding-top: 45px;
  width: clamp(290px, 100%, 403px);
  
  @media (max-width: 768px) {
    padding-top: 0;
    min-height: 350px;
  }
`;

export const SecondStepHeader = styled.h3`
  margin: 0;
  padding: 0 0 32px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: ${(props) => props.theme.colors?.mainColor};

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const SecondStepBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  @supports not (aspect-ratio: 1 / 1) {
    label { margin-bottom: 16px; }
  }
  width: 100%;
`;

export const CompanyItem = styled.label`
  background-color: ${(props) => props.theme.colors?.buttonColor};
  border-radius: 6px;
  min-height: 74px;
  display: flex;
  align-items: center;
  width: clamp(290px, 100%, 403px);
  
  &:last-child {
    margin-bottom: 0;
  }
  
  .radio-btn {
    display: flex;
    place-content: center;
    align-items: center;
    flex: 0 0 72px;
    
    input {
      position: absolute;
      opacity: 0;      
      
      &:checked ~ .checkmark {
        border-color: ${(props) => props.theme.colors?.readMoreColorBtn};
        &::after {
          content: '';
          position: absolute;
          top: 2px;
          left: 2px;
          border-radius: 50%;
          display: block;
          width: 12px;
          height: 12px;
          background-color: ${(props) => props.theme.colors?.readMoreColorBtn} ;
        }
      }
    }

    .checkmark {
      position: relative;
      cursor: pointer;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: white;
      border-radius: 50%;
      border: 2px solid;
      border-color: ${(props) => props.theme.colors?.mainColor};
    }
  }
`;

export const CompanyInfo = styled.div`
  height: 100%;
  border-left: 1px solid ${(props) => props.theme.backgrounds?.inputBg};
  padding: 24px 14px;
`;

export const CompanyName = styled.h4`
  margin: 0;
  padding: 0 0 8px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors?.mainColor};
`;

export const CompanyNumber = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors?.subTitle};
`;

export const SecondStepAction = styled.div`
    padding-top: 24px;
`;

export const ModalContentContainer = styled.div`
  position: relative;
  z-index: 1001;  
  width: 100%;
  height: fit-content;
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  p {
    text-align: center;    
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 30px;
    color: ${(props) => props.theme.colors?.mainColor};
  }
  
  button {
    width: 260px;
    height: 54px;
  }

  @media (max-width: 768px) {
    p {
      font-size: 24px;
    }
  }
`;
