import styled from 'styled-components';

export const StyledContainer = styled.div`
  margin-top: 101px;
  margin-bottom: 120px;

   @media (max-width: 768px) {
     margin-top: ${(props) => (props.pictureRight ? '67px' : '111px')};
     margin-bottom: 24px;
     padding: 0 16px;
     
     div {
       flex-direction: column; 
       align-items: center;
       
       div {
         align-items: flex-start;
       }

       h3, p {
         text-align: start;
       }
     }
   }
`;
