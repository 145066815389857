import React from 'react';
import styled from 'styled-components';

const StyledText = styled.p`
  color: ${(props) => props.color || props.theme.colors?.textColor};
  text-align: ${(props) => props.align || 'left'};
  font-size: ${(props) => props.size || '16px'};
  font-weight: 400;
  margin-bottom: ${(props) => props.marginBottom || '0'};
  line-height: ${(props) => props.lineHeight || 'normal'};
  white-space: pre-line;
`;

export const Text = (props) => <StyledText {...props} />;
