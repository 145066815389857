import styled from 'styled-components';

export const FifthStepItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: clamp(290px, 100%, 716px);
  height: fit-content;
  min-height: 1020px;
  background-color: ${(props) => props.theme.colors?.buttonColor};
  border: 1px solid #607A9B;  
  border-radius: 20px;
  padding: 44px; 
  
  button {
    width: clamp(290px, 100%, 403px);
    margin: 0 auto;
  }

  @media (max-width: 450px) {
    width: calc(100% + 30px);
    margin: -15px;
    padding: 24px 15px;
    min-height: fit-content;
  }
`;

export const FifthStepHeader = styled.h3`    
  font-weight: 700;
  font-size: 28px; 
  text-align: center;
  margin-bottom: 32px;
  color: ${(props) => props.theme.colors?.mainColor};

  @media (max-width: 450px) {
    font-size: 18px;
    margin-bottom: 16px;
  }
`;

export const OfferSummaryInfoWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;  
`;

export const OfferSummaryStepValue = styled.div`
  width: 100%;
  height: fit-content;
  margin-bottom: 24px;
`;

export const OfferSummaryInfoHeader = styled.h4`
  margin-left: 24px;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 16px;

  @media (max-width: 425px) {
    margin-left: 16px;    
  }
`;

export const OfferSummaryInfo = styled.div`
  padding: 24px;
  border: 1px solid #E9F1FF;
  border-radius: 6px;
  
  > div {
    &:last-child {
      margin: 0;
    }
  }
  .guarantor-wrapper:not(:last-child) {
    border-bottom: 1px solid #E9F1FF;
    margin-bottom: 10px;
  }

  @media (max-width: 450px) {
    padding: 24px 12px;
  }
`;

export const OfferSummaryTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.colors?.subTitle};

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

export const OfferSummaryValue = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.colors?.mainColor};

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;
