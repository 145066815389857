import React from 'react';

import { Navigation } from 'UI/components/sections/Navigation/Navigation';
import { Flex } from 'UI/common/components/Flex';
import { usePathMatch } from 'customHooks/usePathMatch';
import { Logo } from './Logo/Logo';
import { BurgerMenu } from './BurgerMenu/BurgerMenu';
import { Language } from './Language/Language';
import { StyledContainer } from './styled';

export const Header = () => {
  const isNavHidden = usePathMatch('en/loan-order-form', 'bestallningsblankett-for-lan');

  return (
    <StyledContainer>
      <Flex justify="space-between" align="center">
        <Logo />
        <div>
          <Flex align="center">
            {!isNavHidden && <Navigation />}
            <Language />
            {!isNavHidden && <BurgerMenu />}
          </Flex>
        </div>
      </Flex>
    </StyledContainer>
  );
};
