import React from 'react';

import { InputContainer, StyledInput } from './styled';

export const Input = ({
  data,
  name,
  background,
  errors,
  value,
  touched,
  handleChange,
  handleBlur,
  marginBottom,
  maxWidth,
  padding,
  isFactoringTextInput,
  disabled,
}) => {
  const factoringNumberTypeChangeInterceptor = (callback) => (event) => {
    const newEvent = { ...event };
    if (data.type === 'text' && isFactoringTextInput) {
      newEvent.target.value = event.target.value.replace(/[^0-9]+/g, '');
    }
    callback(newEvent);
  };

  return (
    <InputContainer marginBottom={marginBottom} maxWidth={maxWidth}>
      <StyledInput
        disabled={disabled}
        type={data.type}
        className={
            errors && touched ? 'input-error' : null
          }
        onChange={factoringNumberTypeChangeInterceptor(handleChange)}
        onBlur={handleBlur}
        value={value}
        name={name}
        placeholder={data.placeholder}
        background={background}
        padding={padding}
        required
      />
      {touched && errors && <p className="error">{errors}</p>}
      <img src={data.icon} alt="" />
    </InputContainer>
  );
};
