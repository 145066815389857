import React from 'react';
import { useTranslation } from 'react-i18next';

import { BlockTitle } from 'UI/common/components/Titles/BlockTitle';
import { Text } from 'UI/common/components/Text';
import { IconBlock } from 'UI/common/components/IconBlock';
import { Flex } from 'UI/common/components/Flex';
import { usePathMatch } from 'customHooks/usePathMatch';
import { StyledContainer, StyledContent } from './styled';

export const Item = ({
  icon, title, text, num,
}) => {
  const isLoan = usePathMatch('foretagslan', 'en/business-loans');
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <StyledContent>
        <Flex direction="column" align={isLoan ? 'flex-start' : 'center'}>
          <IconBlock>
            {isLoan ? num : <img src={icon} alt={title} />}
          </IconBlock>
          <BlockTitle lineHeight="32px">
            {t(title)}
          </BlockTitle>
          <Text lineHeight="26px" align={isLoan ? 'flex-start' : 'center'}>
            {t(text)}
          </Text>
        </Flex>
      </StyledContent>
    </StyledContainer>
  );
};
