import styled from 'styled-components';

export const FourthStepItem = styled.div`
  padding-top: 45px;
  width: clamp(290px, 100%, 403px);

  @media (max-width: 768px) {
    padding-top: 0;
  }
`;

export const FourthStepHeader = styled.h3`
  margin: 0;
  padding: 0 0 32px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: ${(props) => props.theme.colors?.mainColor};

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const FourthStepBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 27px;
  @supports not (aspect-ratio: 1 / 1) {
    div { margin-bottom: 24px; }
  }
  
  div {
   &:last-child {
    margin-bottom: 0;
   }
  }  
  
  input {
    background: ${(props) => props.theme.colors?.buttonColor};
    
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }
`;

export const FourthStepAction = styled.div`
  padding-top: 24px;
`;

export const RemoveGuarantor = styled.div`
  display: flex;
  justify-content: center;
  width: clamp(290px, 100%, 403px);
  cursor: pointer;
  
  p {
    font-weight: 500;
    font-size: 16px;
    color: red;
    margin-right: 14px;
  }  
`;

export const AddNewGuarantor = styled(RemoveGuarantor)`
    p {
      color: ${(props) => props.theme.colors?.mainColor};
    }
`;
