import styled from 'styled-components';
import { SecondStepHeader } from '../../companySelection/styled';
import { ModalHeader } from '../../../ModalContent/styled';

export const FormHeader = styled(SecondStepHeader)`
    padding: 0 0 24px;

    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 22px;
        padding: 0 0 16px;
        }
`;

export const Form = styled.form`
    & * {
        outline:none;
    }

    max-width: 470px;
    margin: 0px auto;
 
    @media (min-width: 768px) {
        padding-top:25px;
        }    
`;

export const FormItem = styled.div`
    &:not(last-child){
        margin-bottom: 32px
    };
    position:relative;

    @media (max-width: 768px) {
        &:not(last-child){
            margin-bottom: 26px
            };
        }
`;

export const FormLabel = styled.label`
    display: block;
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #607B9B;
    cursor:pointer;

    @media (max-width: 768px) {
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 24px;
        }
`;

export const TextArea = styled.textarea`
    font-family: 'Inter';
    min-height: 100px;
    resize: none;
    padding: 14px 24px;
    border-radius: 5px;
    width: 100%;
    transition: all 0.3s ease 0s;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border:none;
    border: ${(props) => (props.hasError ? '1px solid #EB3235' : 'none')};


    &::placeholder {
        color: #607B9B;
    }

    @media (max-width: 768px) {
        padding: 14px 16px;
        }
`;

export const StyledError = styled.div`
    display:${(props) => (props.visible ? 'block' : 'none')};
    position: absolute;
    bottom: -15px;
    left: 5px;
    font-size: 12px;
    color: #EB3235;
`;

export const StyledOptions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 60px;
  
    .options-item {
        cursor: pointer;
        width: 100%;
        background: #fff;
        border-radius: 5px 5px 0px 0px;

        &:last-child {
            border-radius: 5px;
        }
    }
`;

export const OptionsInput = styled.input`
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;

    &:focus + label:before {
        box-shadow: 0 0 2px #000;
    }

    &:checked + label::before {
        border-color: ${(props) => props.theme.colors?.readMoreColorBtn}
    }

    &:checked + label::after {
        transform: translate(4px, -2px) scale(1);
    }
`;

export const OptionsLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    gap: 16px;
    position:relative;
    padding: 16px 24px;
   

    &::before {
        content: "";
        align-self: flex-start;
        height: 20px;
        width: 20px;
        flex: 0 0 20px;
        border-radius: 50%;
        border: 2px solid ${(props) => props.theme.colors?.mainColor};
    }

    &::after {
        content: "";
        transition: all 0.1s ease 0s;
        transform: ;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: ${(props) => props.theme.colors?.readMoreColorBtn};
        position: absolute;
        transform: translate(4px, -3px) scale(0);
        
    } 
`;

export const AddAttachmentWrapper = styled.div`
    background-color: #fff;
    padding: 18px 26px 16px;
    width:100%;
    margin-top:1px;

    .info-items {
        &:not(:last-child){
            margin-bottom:16px;
        }
    }

    .info-item {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #607B9B;
    }

    .files {
        display:flex;
        flex-wrap:wrap;
        gap:12px;
    }

    .file {
        display:inline-flex;
        align-items: center;
        gap:12px;
        border: 1px solid #E9F1FF;
        border-radius: 6px;
        padding: 8px 12px;
    }

    .file-name {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        word-break: break-word;
    }

    .remove-btn {
        cursor:pointer;
    }
`;

export const FormBlock = styled.div`
    margin-bottom: 60px;

    &:nth-last-of-type(1) {
        margin-bottom: 32px; 
    }

    @media (max-width: 768px) {
        margin-bottom: 32px;
        }  
`;

export const AddFileButton = styled.button`
    background: transparent;
    border:none;
    display:flex;
    align-items:center;
    gap:6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 13px;
    cursor:${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};

    @media (any-hover: hover){
        &:hover{
            text-decoration:${(props) => (props.disabled ? 'none' : 'underline')};;
        }
    }
    
`;

export const RemoveFileBtn = styled.button`
        flex: 0 0 20px;
        background:transparent;
        border:none;

            img {
                width:100%;
                height:100%;
                vertical-align: middle;
`;

export const ModalHeading = styled(ModalHeader)`
    maring-bottom: 52px;
`;

export const StyledCollapsible = styled.div`
    width: 100%;
    margin-bottom:12px;

    .Collapsible__trigger {
        display:none;
    }
`;
