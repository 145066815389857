import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';
import { Button } from 'UI/common/components/Button';
import { Loader } from 'UI/common/components/Loader/Loader';
import { ModalWindow } from 'UI/common/components/Modal/Modal';

import {
  CONVERSION_TRACK_CODE, formatRegistrationNum, STEP,
} from 'constants/helpers';
import { fieldsStore } from 'store/forms';
import { companiesStore } from 'store/companies';
import { offersStore } from 'store/offers';
import {
  BASE_API_URL, BASE_FETCH_SETTINGS, OFFERS_URL,
} from 'constants/apiConstants';
import {
  CompanyInfo,
  CompanyItem,
  CompanyName,
  CompanyNumber,
  SecondStepBody,
  SecondStepHeader,
  SecondStepItem,
  SecondStepAction,
} from 'UI/components/pages/LoanStepper/steps/companySelection/styled';
import { ModalContent } from 'UI/components/pages/LoanStepper/ModalContent/ModalContent';
import { userStore } from 'store/user';

export const CompanySelection = () => {
  const [formState, setFormState] = useRecoilState(fieldsStore);
  const { ssn } = useRecoilValue(userStore);
  const companyState = useRecoilValue(companiesStore);
  const offerState = useRecoilValue(offersStore);
  const [showModalWindow, setShowModalWindow] = useState(false);
  const { t } = useTranslation();
  const { companyId, companyName } = formState;

  const {
    patch, response, loading,
  } = useFetch(BASE_API_URL, BASE_FETCH_SETTINGS);

  const handleCloseModal = () => {
    setShowModalWindow(false);
  };

  const handleSelectCompany = (targetCompanyName) => (e) => {
    setFormState(
      {
        ...formState,
        companyId: e.target.value,
        companyName: targetCompanyName,
      },
    );
  };

  const proceedToNextStep = async () => {
    const requestBody = {
      ssn,
      company_registration_number: companyId,
      company_name: companyName,
      passed_step: STEP.CompanySelect,
      offer_confirmed: false,

    };

    await patch(`${OFFERS_URL}${offerState.offerId}/`, requestBody);
    if (response.ok) {
      setFormState({
        ...formState, currentStep: 3,
      });
    } else {
      setShowModalWindow(true);
    }
    if (gtag_report_conversion) {
      gtag_report_conversion(false, CONVERSION_TRACK_CODE.SelectCompany);
    }
  };
  return (
    <SecondStepItem>
      { showModalWindow && (
        <ModalWindow>
          <ModalContent handleCloseModal={handleCloseModal} />

        </ModalWindow>
      )}
      <SecondStepHeader>{t('selectCompany')}</SecondStepHeader>
      <SecondStepBody>
        {loading && <Loader />}
        {!loading && (
          companyState.map((company) => (
            <CompanyItem key={`company-${company.companyId}`}>
              <div className="radio-btn">
                <input
                  type="radio"
                  value={company.companyId}
                  onChange={handleSelectCompany(company.companyName)}
                  checked={
                    formState.companyId === company.companyId
                  }
                />
                <span className="checkmark" />
              </div>
              <CompanyInfo>
                <CompanyName>{company.companyName}</CompanyName>
                <CompanyNumber>{formatRegistrationNum(company.companyId)}</CompanyNumber>
              </CompanyInfo>
            </CompanyItem>
          )))}
      </SecondStepBody>
      <SecondStepAction>
        {!loading && (
          <Button
            formButton
            onClick={proceedToNextStep}
            className={!companyId && 'disabled-btn'}
            disabled={!companyId}
          >
            {t('nextBtn')}
          </Button>
        )}
      </SecondStepAction>
    </SecondStepItem>
  );
};
