import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  width: 100vw;
  height: 100vh;
  background: rgb(39, 45, 78, 0.8);
`;

export const ModalContentWrapper = styled.div`
  border-radius: 6px;
  padding: 15px;
  background: ${(props) => props.theme.colors?.buttonColor};
  background-image: linear-gradient(180deg, #E5EFFF 0%, rgba(229, 239, 255, 0.262661) 83.7%, rgba(229, 239, 255, 0) 100%);
  background-repeat: no-repeat;
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 15px;
  right: 30px;
  z-index: 1000;
  cursor: pointer;

  &:after {
    display: inline-block;
    content: "\\00d7"; /* This will render the 'X' */
    color: #fff;
    font-size: 30px;
  }
`;
