import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalWindow } from 'UI/common/components/Modal/Modal';
import { theme } from 'UI/common/styles/theme';
import {
  ModalButton,
  ModalContentContainer, ModalHeading, ModalText, StyledFlexContainer,
} from './styled';

export const FileErrorModal = ({
  visible,
  title,
  text,
  onClose,
  onRestart,
}) => {
  const { t } = useTranslation();
  return visible && (
  <ModalWindow>
    <ModalContentContainer>
      <ModalHeading>{title}</ModalHeading>
      <ModalText>
        {text}
      </ModalText>
      <StyledFlexContainer>
        <ModalButton
          readMore
          color={theme.colors.readMoreColorBtn}
          background="initial"
          onClick={onClose}
        >
          {t('declineBtn')}
        </ModalButton>
        <ModalButton onClick={onRestart}>
          {t('changeBtn')}
        </ModalButton>
      </StyledFlexContainer>
    </ModalContentContainer>
  </ModalWindow>
  );
};
