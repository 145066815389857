import React, { useEffect } from 'react';

import { Banner } from 'UI/components/pages/Home/Banner/Banner';
import { useGetTranslatedData } from 'utils/contentData';
import { TwoSectionBlock } from 'UI/common/components/TwoSectionBlock/TwoSectionBlock';
import { Faq } from 'UI/common/components/Faq/Faq';
import { Flex } from 'UI/common/components/Flex';
import { factoringFaqData } from 'constants/FaqData';
import { HelmetWrapper } from 'components/HelmetWrapper/HelmetWrapper';
import { TrustPilotWidget } from 'UI/common/components/TrustWidget/TrustPilotWidget';
import i18n from 'i18n';
import { Form } from './Form/Form';
import { StyledContainer, StyledFaqContainer } from './styled';

export const Factoring = () => {
  const data = useGetTranslatedData();
  const language = i18n.language === 'en' ? 'en' : 'se';

  return (
    <>
      <HelmetWrapper />
      <StyledContainer>
        <Flex>
          <Flex direction="column" gap="10px">
            <Banner data={data.factoring.banner} screen="Factoring" />
            <TrustPilotWidget language={language} />
          </Flex>
          <Form />
        </Flex>
        <TwoSectionBlock data={data.factoring} />
      </StyledContainer>
      <StyledFaqContainer>
        <Faq faqArr={factoringFaqData} title="commonQuestionsFactoring" />
      </StyledFaqContainer>
    </>
  );
};
