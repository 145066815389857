import { acceptedCategory } from 'vanilla-cookieconsent';

const addCookieConsentListeners = () => {
  /**
     * React specific fix: avoid adding twice event listeners
     */
  // eslint-disable-next-line no-underscore-dangle
  if (window._ccRun) return;

  // eslint-disable-next-line no-console
  console.log('added cc listeners');

  window.addEventListener('cc:onConsent', () => {
    // eslint-disable-next-line no-console
    console.log('onConsent');

    if (acceptedCategory('analytics')) {
      // eslint-disable-next-line no-console
      console.log('accepted analytics');
    } else {
      // eslint-disable-next-line no-console
      console.log('rejected analytics');
    }
  });

  window.addEventListener('cc:onChange', (event) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { changedCategories, changedServices } = (
      event
    )?.detail;

    // eslint-disable-next-line no-console
    console.log('onChange:changedCategories', changedCategories);
    // eslint-disable-next-line no-console
    console.log('onChange:changedServices', changedServices);

    if (changedCategories.includes('analytics')) {
      if (acceptedCategory('analytics')) {
        console.log('accepted analytics');
      } else {
        console.log('rejected analytics');
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const category of changedCategories) {
      // eslint-disable-next-line no-console
      console.log(`${category} accepted:`, acceptedCategory(category));
    }
  });
};

export default addCookieConsentListeners;
