import styled from 'styled-components';

export const StyledContainer = styled.div`
  max-width: 1090px;
  margin: 0 auto;

  .Collapsible {
    background: #fff;
    border-radius: 36px;
    width: clamp(290px, 100%, 590px);
    margin-bottom: 16px;
    cursor: pointer;    
  }
  
  .Collapsible .Collapsible__trigger {
    display: block;
    width: 100%;
  }

  .Collapsible__contentInner {
    padding: 24px 26px 24px 44px;;

    @media (max-width: 768px) {
      padding-left: 24px;
    }
  }

  .Collapsible .Collapsible__trigger span {
    display: block;
    flex: 1 1 auto;
    padding: 0 24px;
  }

  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text {
    font-size: 16px !important;
    line-height: 26px;
    color: #607B9B;
  }

  @media (max-width: 768px) {
    padding: 0 16px; 

    .Collapsible {
        border-radius: 20px;
        min-height: 84px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .titleContainer span {
        flex: 1 1 auto;
        padding: 0 24px;
        max-width: 247px;
    }           
    
    h3 { 
      margin-bottom: 24px;
    }
  }
`;

export const AccordionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  @supports not (aspect-ratio: 1 / 1){
    .Collapsible { margin-bottom: 16px; }
  }
`;

export const AccordionItemHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 26px 24px 44px;
  align-items: center;
  gap: 24px;
  @supports not (aspect-ratio: 1 / 1){
    h6 { margin-right: 24px; }
  }

  @media (max-width: 768px) {
    height: 84px;
    padding: 16px 24px;
  }
`;

export const AccordionItemTitle = styled.h6`
  flex: 1 1 auto;
  font-weight: 400;
  font-size: 18px!important;
  line-height: 26px;
  color: ${(props) => props.theme.colors?.mainColor};

  @media (max-width: 768px) {
    font-size: 16px !important;
  }
`;

export const AccordionItemContent = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px !important;
  line-height: 26px;
  white-space: pre-line;
  color: ${(props) => props.theme.colors?.textColor};;
`;
