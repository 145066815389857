import styled from 'styled-components';

export const StyledContainer = styled.div`
  margin: 100px 0 0;

  @media (max-width: 768px) {
    margin: 60px 0 ;
  }
`;

export const PartnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;  
  flex-wrap: wrap;
  
  img {
    margin-right: 50px;
    margin-bottom: 30px;
    
    &:last-child {
      margin-right: 0;      
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    img {
      margin-right: 0;
      margin-bottom: 50px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const StyledImage = styled.img`
  max-width: 288px;  
`;
