import React from 'react';
import { useTranslation } from 'react-i18next';

import thankYou from 'UI/assets/images/thankYouFactoring.png';
import thankYouCont from 'UI/assets/images/thankYouContact.png';
import { usePathMatch } from 'customHooks/usePathMatch';
import { StyledContainer } from './styled';

export const ThankYouLayout = () => {
  const { t } = useTranslation();
  const isContact = usePathMatch('kontakt', 'en/contact');

  return (
    <StyledContainer isContact={isContact}>
      <h3>{t('thankYou')}</h3>
      <p>{t('weWillRichYou')}</p>
      <img src={isContact ? thankYouCont : thankYou} alt="thank-you" />
    </StyledContainer>
  );
};
