import styled from 'styled-components';
import { Button } from 'UI/common/components/Button';
import { Flex } from 'UI/common/components/Flex';
import { ModalHeader, ModalTitle } from '../../../ModalContent/styled';

export const ModalHeading = styled(ModalHeader)`
    maring-bottom: 52px;

    @media (max-width: 768px) {
        font-weight:500;
        margin-bottom: 16px;
        }
`;

export const ModalContentContainer = styled.div`
    display: flex;
    position: relative;
    z-index: 1001;    
    padding: 25px 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 475px;
    min-width: 290px;
`;

export const ModalText = styled(ModalTitle)`
    margin-bottom:44px;
    span {
        font-weight: 600;
        color:${(props) => props.theme.colors.textColor2}
    }

    @media (max-width: 768px) {
        margin-bottom: 24px;
        }
`;

export const ModalButton = styled(Button)`
    padding: 16px 15px;
    flex: 0 1 48%;
    min-width: 205px;

    @media (max-width: 768px) {
        flex: 1 1 100%;
        }

`;

export const StyledFlexContainer = styled(Flex)`
        flex-wrap:wrap;
        gap:16px;
        justify-content:center;
        flex-wrap:wrap;
        align-self: stretch;

        @media (max-width: 768px) {
            flex-direction: column-reverse;
            }
`;
