import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const MobWrapper = styled.div`
  overflow: hidden;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100% + 150px);  
  background-color: #FFF;
`;

export const MobWrapperGradient = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column ;
  padding: 24px 16px;
  overflow-y: scroll;
  background: linear-gradient(180deg, #E5EFFF 0%, rgba(229, 239, 255, 0.262661) 83.7%, rgba(229, 239, 255, 0) 100%); ;
`;

export const CloseMenu = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin-left: 14px;
  border: 1px solid #272D4E;
  border-radius: 6px;
  cursor: pointer;
  `;

export const WrapperMobNav = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  margin-bottom: 63px;
  a {
    font-size: 26px;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0; 
    }
  }
`;

export const SocialMob = styled.div`
    .social__icon {
      width: 18px;
      height: 18px;   
    } 
`;

export const StyledLink = styled(NavLink)`
  font-family: 'inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors?.mainColor};
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }

  &.active {
    font-weight: 700;
  }
  :hover{
    color:${(props) => props.theme.colors.hoverColor};
    transition: all 0.3s;
  }
`;

export const FirstColumnMob = styled.div`
  width: 185px;
  max-height: fit-content;  

  p {
    font-size: 14px;
    line-height: 24px;
    color: #272D4E;
    &:first-child {
      margin-bottom: 16px;
    }
  }

`;

export const SecondColumnMob = styled.div`
  max-height: fit-content;

    p {
    font-size: 14px;
    line-height: 24px;
    color: #272D4E;
    &:first-child {
      margin-bottom: 16px;
    }
  }

    .mail, .phone {
      a {
        &:hover {
          cursor: pointer;          
          color:  #815EFD;
          text-decoration: underline;
        }
      }
    }
`;
