import React from 'react';
import { Link } from 'react-router-dom';

import { Title } from 'UI/common/components/Titles/Title';
import { SpanText } from 'UI/common/components/Titles/SpanText';
import { Button } from 'UI/common/components/Button';
import { Flex } from 'UI/common/components/Flex';

import {
  StyledBackground, StyledContainer, StyledContent, LeftSide, RightSide,
} from './styled';

export const ContentBanner = ({
  data, maxWidth, spanTextMargin, padding, marginRight, paddingBottom,
}) => (
  <StyledBackground>
    <StyledContainer>
      <StyledContent padding={padding}>
        <Flex align="center">
          <LeftSide
            maxWidth={maxWidth}
            marginRight={marginRight}
            paddingBottom={paddingBottom}
          >
            <Title size="66px" lineHeight="82px">
              {data.contentBanner.title}
            </Title>
            <SpanText display="block" margin={spanTextMargin}>
              {data.contentBanner.span}
            </SpanText>
            <Link to="factoring">
              <Button submit>
                {data.contentBanner.button}
              </Button>
            </Link>
          </LeftSide>
          <RightSide>
            <img src={data.contentBanner.image} alt={data.contentBanner.image_alt_text || ""} style={{ width: '100%' }} />
          </RightSide>
        </Flex>
      </StyledContent>
    </StyledContainer>
  </StyledBackground>
);
