import React, { useState } from 'react';
import { Formik } from 'formik';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Input } from 'UI/common/components/Input/Input';
import { useGetTranslatedData } from 'utils/contentData';
import { Button } from 'UI/common/components/Button';
import { Flex } from 'UI/common/components/Flex';
import { InputRangeForm } from 'UI/common/components/InputRangeForm/InputRangeForm';
import { LOAN_TERMS, phoneRegExp } from 'constants/helpers';
import { ModalWindow } from 'UI/common/components/Modal/Modal';
import { Policy } from 'components/Policy/Policy';
import { fieldsStore } from 'store/forms';
import { Loader } from 'UI/common/components/Loader/Loader';
import {
  StyledContainer,
  StyledContent,
  StyledPolicy,
  StyledSpan,
  StyledText,
} from './styled';

export const Form = ({ loading, onNextStep }) => {
  const [showModalWindow, setShowModalWindow] = useState(false);
  const [formState, setFormState] = useRecoilState(fieldsStore);
  const { t } = useTranslation();
  const data = useGetTranslatedData();

  const initialValues = {
    contactEmail: '',
    contactPhoneNumber: '',
    amount: 30000,
    loanTermInMonths: LOAN_TERMS.min,
  };

  const validationSchema = Yup.object().shape({
    contactEmail: Yup.string()
      .email(t('inputErrorEmail'))
      .required(t('inputErrorRequired')),
    contactPhoneNumber: Yup.string()
      .matches(phoneRegExp, t('inputErrorPhone'))
      .required(t('inputErrorRequired')),
    amount: Yup.number().min(30000, t('inputErrorMin')),
  });

  const openModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowModalWindow(true);
  };

  const closeModal = () => {
    setShowModalWindow(false);
  };

  const onCheckBoxToggle = () => {
    setFormState({ ...formState, loanAgree: !formState.loanAgree });
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur
      onSubmit={onNextStep}
    >
      {({
        values,
        setFieldValue,
        errors,
        isValid,
        touched,
        dirty,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <StyledContainer>
          {showModalWindow && (
            <ModalWindow closeAction={closeModal} showIcon>
              <Policy />
            </ModalWindow>
          )}
          <StyledContent>
            <InputRangeForm
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
            {data.loan.form.map((item) => (
              <Input
                key={`key-${item.name}`}
                data={item}
                name={item.name}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values[item.name]}
                touched={touched[item.name]}
                errors={errors[item.name]}
                marginBottom="24px"
                maxWidth="none"
              />
            ))}
            <StyledPolicy>
              <Flex>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="container">
                  <StyledText>
                    {t('agreeForm')}
                    {' '}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" onClick={openModal}>
                      {t('conditionsForm')}
                    </a>
                  </StyledText>
                  <input
                    name="loanAgree"
                    onChange={onCheckBoxToggle}
                    checked={formState.loanAgree}
                    type="checkbox"
                  />
                  <span className="checkmark" />
                </label>
              </Flex>
            </StyledPolicy>
            <Button
              formButton
              type="submit"
              onClick={handleSubmit}
              className={!(dirty && isValid) && 'disabled-btn'}
              disabled={!(dirty && isValid && formState.loanAgree) || loading}
            >
              {t('startApplicationBtn')}
            </Button>
            {loading && <Flex justify="center"><Loader /></Flex> }

            <StyledSpan>{t('applicationIsFree')}</StyledSpan>
          </StyledContent>
        </StyledContainer>
      )}
    </Formik>
  );
};
