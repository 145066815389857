import React from 'react';

import { Flex } from 'UI/common/components/Flex';
import { Item } from './Item/Item';

export const Items = ({ dataArray }) => (
  <Flex>
    {dataArray.map((item) => (
      <Item
        key={item.id}
        icon={item.icon}
        title={item.title}
        text={item.text}
        num={item.id}
      />
    ))}
  </Flex>
);
