import React from 'react';

import logo from 'UI/assets/images/logo.svg';
import { StyledWrapper } from './styled';

export const Progress = () => (
  <StyledWrapper>
    <img src={logo} alt="logo" />
  </StyledWrapper>
);
