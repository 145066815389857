export const urlNameMaper = {
  home: 'home',
  about: 'about',
  'om-oss': 'about',
  contact: 'contact',
  kontakt: 'contact',
  factoring: 'factoring',
  'business-loans': 'business-loans',
  foretagslan: 'business-loans',
  'loan-order-form': 'loan-order-form',
  'bestallningsblankett-for-lan': 'loan-order-form',
  404: 404,
};

export const altUrlNameMaper = {
  about: 'om-oss',
  'om-oss': 'about',
  contact: 'kontakt',
  kontakt: 'contact',
  factoring: 'factoring',
  'business-loans': 'foretagslan',
  foretagslan: 'business-loans',
  'loan-order-form': 'bestallningsblankett-for-lan',
  'bestallningsblankett-for-lan': 'loan-order-form',
  404: 404,
};
