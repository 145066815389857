import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Title } from 'UI/common/components/Titles/Title';
import { SpanText } from 'UI/common/components/Titles/SpanText';
import { Button } from 'UI/common/components/Button';
import tel from 'UI/assets/images/icons/calling.svg';
import email from 'UI/assets/images/icons/email.svg';
import Tick from 'UI/assets/images/icons/tick';
import {
  StyledContainer,
  SpanContainer,
  StyledContent,
  StyledTitleWrapper,
  StyledTreeItemsContainer,
  MobileBlock,
} from './styled';

export const Banner = ({
  data, width, screen, about,
}) => {
  const { i18n } = useTranslation();
  const pathToBusinessPage = useMemo(() => (i18n.language === 'en' ? '/en/business-loans' : '/foretagslan'), [i18n.language]);

  return (
    <StyledContainer about={about}>
      <StyledContent>
        <StyledTitleWrapper width={width} screen={screen}>
          <Title margin="0 0 24px 0">

            {data.title}
          </Title>
        </StyledTitleWrapper>
        <SpanContainer screen={screen}>
          <SpanText margin="0 0 44px 0" display="block">
            {data.span}
          </SpanText>
        </SpanContainer>
        {
              (screen === 'Factoring' || screen === 'Loan')
              && (
              <StyledTreeItemsContainer>
                <div className="items__block">
                  <div className="item__block">
                    <Tick />
                    <span>{data.items.item1}</span>
                  </div>
                  <div className="item__block">
                    <Tick />
                    <span>{data.items.item2}</span>
                  </div>
                  <div className="item__block">
                    <Tick />
                    <span>{data.items.item3}</span>
                  </div>
                </div>
              </StyledTreeItemsContainer>
              )
          }

        {
              data.button.showButton
              && (
              <Link to={pathToBusinessPage}>
                <Button>{data.button.text}</Button>
              </Link>
              )
          }
        {
              data.showMobileBlock
              && (
              <MobileBlock>
                <div className="telBlock block">
                  <img src={tel} alt="" />
                  <a href="tel:0843733145" className="tel"><span>08-43733145</span></a>
                </div>
                <div className="emailBlock block">
                  <img src={email} alt="" />
                  <a href="mailto:info@financed.se" className="email"><span>info@financed.se</span></a>
                </div>
              </MobileBlock>
              )
          }
      </StyledContent>
    </StyledContainer>
  );
};
