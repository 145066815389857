import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import 'yup-personnummer';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';

import { Button } from 'UI/common/components/Button';
import { Formik } from 'formik';
import { Input } from 'UI/common/components/Input/Input';
import { inputTypeNumberInterceptor } from 'constants/helpers';
import { Loader } from 'UI/common/components/Loader/Loader';
import { ModalWindow } from 'UI/common/components/Modal/Modal';
import { fieldsStore } from 'store/forms';
import { companiesStore } from 'store/companies';
import bankId from 'UI/assets/images/icons/bankId.svg';
import {
  FirstStepAction,
  FirstStepBody,
  FirstStepHeader,
  FirstStepHeaderText,
  FirstStepItem,
} from 'UI/components/pages/LoanStepper/steps/identification/styled';
import {
  BASE_API_URL,
  BASE_FETCH_SETTINGS,
  COMPANIES_URL,
} from 'constants/apiConstants';
import { offersStore } from 'store/offers';
import { ModalContent } from 'UI/components/pages/LoanStepper/ModalContent/ModalContent';
import { useSearchParams } from 'react-router-dom';
import { userStore } from 'store/user';
import { ContactMessage } from './ContactMessage';

const AUTH_STATUS = {
  complete: 'complete',
  failed: 'failed',
};

const AUTH_ERROR = {
  noCompany: 'noCompaniesFound',
  authFail: 'authFail',
};

export const Identification = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [formState, setFormState] = useRecoilState(fieldsStore);
  const { offerId } = useRecoilValue(offersStore);
  const setCompaniesState = useSetRecoilState(companiesStore);
  const setUserState = useSetRecoilState(userStore);
  const [authInProgess, setAuthInProgress] = useState(false);
  const { i18n, t } = useTranslation();
  const [error, setError] = useState(null);
  const initialValues = {
    ssn: '',
  };
  const transactionId = searchParams.get('transaction_id');

  const validationSchema = Yup.object().shape({
    ssn: Yup.string()
      .matches(/^(\d{12})$/, t('inputErrorExactlyTwelve'))
      .required(t('inputErrorRequired'))
      .personnummer(t('invalidUserSsn')),
  });

  const { post, response, loading } = useFetch(
    BASE_API_URL,
    BASE_FETCH_SETTINGS,
  );

  const {
    get: authGet,
    post: authPost,
    response: authRes,
    loading: authLoading,
  } = useFetch('');

  const handleCloseModal = () => {
    setError(null);
  };

  const startAuth = async ({ ssn }) => {
    setAuthInProgress(true);
    await authPost('/auth-start', {
      ssn,
      redirectPath: `${i18n.language === 'en' ? 'en/' : ''}${t(
        'urlNameLoanStepper',
      )}`,
    });
    if (authRes.ok) {
      window.location.href = authRes.data.accessUrl;
    } else {
      setAuthInProgress(false);
      setError(t(AUTH_ERROR.authFail));
    }
  };
  const retriewCompanies = async (ssn) => {
    const payload = {
      ssn,
      offer_id: offerId,
    };

    await post(COMPANIES_URL, payload);
    const { companies, user_name: userName } = response.data;

    if (response.ok && companies.length > 0) {
      setCompaniesState(companies);
      setFormState({ ...formState, userName, currentStep: 2 });
    } else {
      setError(t(AUTH_ERROR.noCompany));
    }
  };
  const clearQueryParams = () => {
    searchParams.delete('transaction_id');
    searchParams.delete('success');
    setSearchParams(searchParams);
  };
  const finishAuth = async (id) => {
    const verifiedSuccessfully = searchParams.get('success') === 'true';
    if (!verifiedSuccessfully) {
      setError(t(AUTH_ERROR.authFail));
      return clearQueryParams();
    }
    await authGet(`/check-auth/${id}`);
    const { status, providerParameters } = authRes.data;

    if (authRes.ok && status === AUTH_STATUS.complete) {
      const ssn = providerParameters.auth.seBankID.personalNumber;
      setUserState({ ssn });
      retriewCompanies(ssn);
    } else {
      setError(t(AUTH_ERROR.authFail));
    }
    return clearQueryParams();
  };

  useEffect(() => {
    if (transactionId) finishAuth(transactionId);

    return () => {
      setAuthInProgress(false);
    };
  }, [transactionId]);
  const spinnerVisible = loading || authLoading || authInProgess;
  const formVisible = !spinnerVisible && !transactionId;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur
      onSubmit={startAuth}
    >
      {({
        values,
        errors,
        isValid,
        touched,
        dirty,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <FirstStepItem>
          {error && (
            <ModalWindow>
              <ModalContent
                title={error}
                text={<ContactMessage />}
                handleCloseModal={handleCloseModal}
              />
            </ModalWindow>
          )}
          <FirstStepHeader>
            <img src={bankId} alt="bank id icon" />
            <FirstStepHeaderText>{t('identification')}</FirstStepHeaderText>
          </FirstStepHeader>
          <FirstStepBody>
            {spinnerVisible && <Loader />}
            {formVisible && (
              <Input
                data={{
                  placeholder: `${t('EnterSSN')}`,
                  icon: null,
                  type: 'text',
                }}
                name="ssn"
                handleChange={inputTypeNumberInterceptor(handleChange)}
                handleBlur={handleBlur}
                maxWidth="none"
                value={values.ssn}
                touched={touched.ssn}
                errors={errors.ssn}
                disabled={spinnerVisible}
              />
            )}
          </FirstStepBody>
          <FirstStepAction>
            {formVisible && (
              <Button
                formButton
                type="submit"
                onClick={handleSubmit}
                className={!(dirty && isValid) && 'disabled-btn'}
                disabled={!(dirty && isValid)}
              >
                {t('nextBtn')}
              </Button>
            )}
          </FirstStepAction>
        </FirstStepItem>
      )}
    </Formik>
  );
};
