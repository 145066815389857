import discount from 'UI/assets/images/icons/discount.svg';
import timeCircle from 'UI/assets/images/icons/timeCircle.svg';
import star from 'UI/assets/images/icons/star.svg';

export const threeItemsHomeData = [
  {
    id: 1,
    icon: discount,
    title: 'betterTerms',
    text: 'bestPossibleTerms',
  },
  {
    id: 2,
    icon: timeCircle,
    title: 'timeEfficient',
    text: 'apply',
  },
  {
    id: 3,
    icon: star,
    title: 'satisfiedCustomers',
    text: 'helpedBusinesses',
  },
];

export const threeItemsLoanData = [
  {
    id: 1,
    icon: discount,
    title: 'priceProposal',
    text: 'chooseLoan',
  },
  {
    id: 2,
    icon: timeCircle,
    title: 'loanOffer',
    text: 'tailoredOffer',
  },
  {
    id: 3,
    icon: star,
    title: 'signWithBankID',
    text: 'approveYourOffer',
  },
];

export const threeItemsAboutUsData = [
  {
    id: 1,
    icon: discount,
    title: 'simple',
    text: 'fewDetails',
  },
  {
    id: 2,
    icon: timeCircle,
    title: 'knowledgeable',
    text: 'extensiveExperience',
  },
  {
    id: 3,
    icon: star,
    title: 'available',
    text: 'justClickAway',
  },
];
