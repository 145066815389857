import React, { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'UI/common/components/Button';
import thankYou from 'UI/assets/images/factoringBannerConent.png';
import { theme } from 'UI/common/styles/theme';
import { fieldsInitial, fieldsStore } from 'store/forms';
import { initialUserState, userStore } from 'store/user';
import { companiesStore } from 'store/companies';
import { offersStore } from 'store/offers';
import {
  SixthStepItem,
  SixthStepHeader,
  SixthStepTitle,
  ThankYouImg,
} from 'UI/components/pages/LoanStepper/steps/thankYouPage/styled';

export const ThankYouPage = () => {
  const [, setFormState] = useRecoilState(fieldsStore);
  const [, setCompanyState] = useRecoilState(companiesStore);
  const [, setUserState] = useRecoilState(userStore);
  const [, setOffersState] = useRecoilState(offersStore);
  const { i18n } = useTranslation();
  const pathToFactoringPage = useMemo(() => (i18n.language === 'en' ? '/en/factoring' : '/factoring'), [i18n.language]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const resetStore = () => {
    setFormState(fieldsInitial);
    setUserState(initialUserState);
    setOffersState({});
    setCompanyState([]);
  };

  const handleRedirectToHome = () => {
    resetStore();
  };

  const handleRedirectToLoan = () => {
    navigate(pathToFactoringPage);
    resetStore();
  };

  return (
    <SixthStepItem>
      <SixthStepHeader>{t('offerThankYou')}</SixthStepHeader>
      <SixthStepTitle>{t('weWillRichYou')}</SixthStepTitle>
      <SixthStepTitle>{t('otherFinancialServices')}</SixthStepTitle>
      <Button onClick={handleRedirectToHome}>
        {t('offerOk')}
      </Button>
      <Button
        readMore
        color={theme.colors.readMoreColorBtn}
        background="initial"
        margin="16px 0 0 0"
        onClick={handleRedirectToLoan}
      >
        {t('findMoreBtn')}
      </Button>
      <ThankYouImg src={thankYou} alt="thank you" />
    </SixthStepItem>
  );
};
