import styled from 'styled-components';

import faqGradient from 'UI/assets/images/mapBg.png';

export const StyledContainer = styled.div`
  max-width: 1120px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;

  @media (max-width: 768px) {    
    padding: 0;
    
    > div {
      &:first-child {
         flex-direction: column;
         > div {
           &:first-child {
             h2 {
               max-width: 100%;
             }
             div {
               div {
                 max-width: 100%;                
                 }
               }
             }
           }
         }
      &:last-child {
        margin-top: 60px;
      }  
       }
     }
     .trustpilot-widget {
      margin: auto 0px 20px 0px;
     }
`;

export const StyledFaqContainer = styled.div`
  background-image: url(${faqGradient});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 182px;

  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 100px;
  }
`;
