import React from 'react';

import { useGetTranslatedData } from 'utils/contentData';
import { Flex } from 'UI/common/components/Flex';
import { HelmetWrapper } from 'components/HelmetWrapper/HelmetWrapper';
import { Banner } from '../Home/Banner/Banner';
import { FormContactUs } from './FormContactUs/FormContactUs';
import { StyledContainer } from './styled';

export const ContactUs = () => {
  const data = useGetTranslatedData();

  return (
    <>
      <HelmetWrapper />
      <StyledContainer>
        <Flex>
          <Banner data={data.contactUs.banner} width="599px" />
          <FormContactUs />
        </Flex>
      </StyledContainer>
    </>
  );
};
