import styled from 'styled-components';

export const LoanStepperContainer = styled.div`
  width: clamp(320px, 100%, 1120px);
  padding: 80px 15px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(742px - 68px);
  position: relative;
  z-index: 1000;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding-top: 40px;
    min-height: fit-content;
  }
`;

export const StepperHeader = styled.div`
  width: 100%;
  display: flex;
  place-content: center;
  align-items: center;
  gap: 44px;
  @supports not (aspect-ratio: 1 / 1) {
    div { margin-right: 44px; }
  }
  flex-wrap: wrap;
  
  @media (max-width: 450px) {
    gap: 12px;
    @supports not (aspect-ratio: 1 / 1) {
      div { margin-right: 12px; }
    }
    .step-text {
      display: none;
    }

    & > div:not(.active) {
      flex: 0 0 20px;
    }

    & > .active {
      flex: 1 1 auto;

      .step-text {
        display: block;
      }
    }
  }
`;

export const StepItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @supports not (aspect-ratio: 1 / 1) {
    .step-icon { margin-right: 8px; }
  }

  .step-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: ${(props) => props.theme.colors?.mainColor};

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .step-icon {
    width: 17px;
    height: 17px;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: ${(props) => props.theme.colors?.mainColor};
    color: ${(props) => props.theme.colors?.buttonColor};
    display: flex;
    place-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    position: relative;
  }

  &.completed, &.active {
    .step-icon {
      background-color: ${(props) => props.theme.colors?.readMoreColorBtn};
    }
  }

  &.completed .step-icon:after {
    content: "";
    position: absolute;
    left: 6px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-radius: 1px;
  }
`;

export const StepperContent = styled.div`
  padding-top: 40px;
  display: flex;
  place-content: center;
  align-items: center;
  width: 100%;
`;
