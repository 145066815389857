import styled from 'styled-components';

export const IconMobMenu = styled.div`
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin-left: 14px;
  border: 1px solid #272D4E;
  border-radius: 6px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
  }
`;