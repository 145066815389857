export const homeAndAboutFaqData = [
  {
    id: 1,
    title: 'questionOfferService',
    content: 'answerOfferService',
  },
  {
    id: 2,
    title: 'questionWhyShouldIUseYou',
    content: 'answerWhyShouldIUseYou',
  },
];

export const businessFaqData = [
  {
    id: 1,
    title: 'questionHowMuch',
    content: 'answerHowMuch',
  },
  {
    id: 2,
    title: 'questionWhoCanApply',
    content: 'answerWhoCanApply',
  },
  {
    id: 3,
    title: 'questionWhatGuarantor',
    content: 'answerWhatGuarantor',
  },
  {
    id: 4,
    title: 'questionHowDoApply',
    content: 'answerHowDoApply',
  },
  {
    id: 5,
    title: 'questionHowDoPayBack',
    content: 'answerHowDoPayBack',
  },
  {
    id: 6,
    title: 'questionRedeemLoanEarly',
    content: 'answerRedeemLoanEarly',
  },
];

export const factoringFaqData = [
  {
    id: 1,
    title: 'questionHowFactoringWork',
    content: 'answerHowFactoringWork',
  },
  {
    id: 2,
    title: 'questionHowGetStarted',
    content: 'answerHowGetStarted',
  },
  {
    id: 3,
    title: 'questionWhichInvoicesSell',
    content: 'answerWhichInvoicesSell',
  },
];
