import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Identification } from 'UI/components/pages/LoanStepper/steps/identification/Identification';
import { GuarantorsSelection } from 'UI/components/pages/LoanStepper/steps/guarantors/GuarantorsSelection';
import { Summary } from 'UI/components/pages/LoanStepper/steps/summary/Summary';
import { ThankYouPage } from 'UI/components/pages/LoanStepper/steps/thankYouPage/ThankYouPage';
import {
  LoanStepperContainer,
  StepItem,
  StepperContent,
  StepperHeader,
} from 'UI/components/pages/LoanStepper/styled';
import { HelmetWrapper } from 'components/HelmetWrapper/HelmetWrapper';
import { fieldsStore } from 'store/forms';
import { FinancialReport } from './steps/financialReport/FinancialReport';
import { CompanySelection } from './steps/companySelection/CompanySelection';

const steps = [
  { stepId: 1, stepText: 'getCompanyEngagements' },
  { stepId: 2, stepText: 'selectCompany' },
  { stepId: 3, stepText: 'creditDetails' },
  { stepId: 4, stepText: 'fillGuarantorInf' },
];

export const LoanStepper = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formState = useRecoilValue(fieldsStore);

  const {
    amount,
    loanTermInMonths,
    loanAgree,
    contactEmail,
    contactPhoneNumber,
  } = formState;

  useEffect(() => {
    if (
      !amount
      || !loanTermInMonths
      || !loanAgree
      || !contactEmail
      || !contactPhoneNumber
    ) {
      navigate('/');
    }
  }, [formState]);

  const defineStepClassName = (stepId) => {
    let returnedClassName = '';
    if (formState.currentStep > stepId) {
      returnedClassName = 'completed';
    } else if (formState.currentStep === stepId) {
      returnedClassName = 'active';
    }
    return returnedClassName;
  };

  return (
    <>
      <HelmetWrapper />
      <LoanStepperContainer>
        {formState.currentStep <= 4 && (
          <StepperHeader>
            {steps
              .map((step, idx) => (
                <StepItem
                  key={`step-item-${step.stepId}`}
                  className={defineStepClassName(step.stepId)}
                >
                  <div className="step-icon">
                    {formState.currentStep <= step.stepId ? idx + 1 : ''}
                  </div>
                  <div className="step-text">{t(step.stepText)}</div>
                </StepItem>
              ))}
          </StepperHeader>
        )}
        <StepperContent>
          {formState.currentStep === 1 && <Identification />}
          {formState.currentStep === 2 && <CompanySelection />}
          {formState.currentStep === 3 && <FinancialReport />}
          {formState.currentStep === 4 && <GuarantorsSelection />}
          {formState.currentStep === 5 && <Summary />}
          {formState.currentStep === 6 && <ThankYouPage />}
        </StepperContent>
      </LoanStepperContainer>
    </>
  );
};
