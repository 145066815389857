import i18next from 'i18next';

export const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const inputTypeNumberInterceptor = (callback) => (event) => {
  const newEvent = { ...event };
  newEvent.target.value = event.target.value.replace(/[^0-9]+/g, '');
  if (newEvent.target.value.length >= 0) callback(newEvent);
};

export const STEP = {
  InitialOfferData: 'INITIAL_OFFER_DATA',
  CompanySelect: 'COMPANY_SELECT',
  CreditDetails: 'CREDIT_DETAILS',
  LoanGuarantee: 'LOAN_GUARANTEE',
  AcceptOffer: 'ACCEPTED_OFFER',
};

export const CONVERSION_TRACK_CODE = {
  Factoring: 'AW-10882443465/FTGqCO7firwDEMnRk8Uo',
  SelectCompany: 'AW-10882443465/mD0FCOrI0-YDEMnRk8Uo',
  Summary: 'AW-10882443465/tkHSCPrRmMADEMnRk8Uo',
  BusinessLoan: 'AW-10882443465/e6zNCLXwu5MYEMnRk8Uo',
};

export const LOAN_TERMS = {
  min: 12,
  max: 60,
};
export const LARGE_LOAN_TERMS = {
  min: 60,
  max: 180,
};

export const LOAN_AMOUNT_LIMITS = {
  min: 30000,
  max: 3000000,
};
export const LARGE_LOAN_MAX_AMOUNT = 30000000;
export const findMonthsInStr = (string) => string.split(' ').filter((item) => Number.isInteger(parseInt(item, 10)));

export const periodFormatter = (monthsCount) => {
  if (monthsCount <= 60) { return `${monthsCount} ${i18next.t('loanMonths')}`; }
  const years = Math.floor(monthsCount / 12);
  return `${years} ${i18next.t('year', { count: years })}`;
};

export const applyLocale = (value) => value.toLocaleString('sv-SE');

export const formatRegistrationNum = (num) => `${num.substring(0, 6)}-${num.substring(6)}`;
