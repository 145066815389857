import styled from 'styled-components';

export const StyledContainer = styled.div`
  flex: 0 1 45%;
  background: #fff;
  border-radius: 20px;
  margin-top: 104px;

   @media (max-width: 768px) {
     margin-top: 30px;
    }
`;

export const StyledContent = styled.div`
  padding: 32px 32px 24px 32px;
  position: relative ;

  .error {
    position: absolute; 
    bottom: 88px;
    font-size: 12px;
    color: #EB3235;
  }
  
  .text-area-error {
    position: absolute;
    top: 405px;
    font-size: 12px;
    color: #EB3235;
  }

  .text-area-error {
    position: absolute;
    top: 405px;
    font-size: 12px;
    color: #EB3235;
  }

   @media (max-width: 768px) {
     padding: 24px 16px;
   }
`;

export const StyledTextarea = styled.textarea`
  width: 100%;
  border-radius: 6px;
  background: ${(props) => props.theme.backgrounds?.inputBg};
  padding: 14px 24px;
  font-size: 16px;
  font-family: 'inter', sans-serif;
  font-weight: 500;
  color: ${(props) => props.theme.colors?.mainColor};
  margin-bottom: 24px;
  border: none;
  resize: none;
  height: 100px;

  &.input-error {
    border: 1px solid #EB3235;
  } 
 
  &:focus {
    outline: none;
    &::placeholder {
      color: ${(props) => props.theme.colors?.mainColor};
    } 
  }

  &::placeholder {
    color: ${(props) => props.theme.colors?.textColor};
  }

   &:focus {
    &::-moz-placeholder {
      color: ${(props) => props.theme.colors?.mainColor};
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    padding-left: 16px;
  }
`;

export const WrapperRecaptcha = styled.div`
  display: flex;
  justify-content: center;
`;
