export const theme = {
  colors: {
    subTitle: '#607B9B',
    mainColor: '#272D4E',
    buttonColor: '#fff',
    textColor: '#607B9B',
    textColor2: '#757D94',
    readMoreColorBtn: '#815EFD',
    hoverColor: '#815EFD',
  },
  backgrounds: {
    buttonBackground: '#815EFD',
    iconBackground: '#E9F1FF',
    inputBg: '#E9F1FF',
    gradient: 'linear-gradient(180deg, #E5EFFF 0%, rgba(229, 239, 255, 0.262661) 83.7%, rgba(229, 239, 255, 0) 100%)',
    gradientFull: 'linear-gradient(180deg, #E5EFFF 0%, rgba(229, 239, 255, 0.262661) 100%, rgba(229, 239, 255, 0) 100%)',
  },
  media: {
    phone: '(max-width: 425px)',
    tablet: '(max-width; 768px) and (min-width: 425px)',
  },
};
