import styled from 'styled-components';

import faqGradient from 'UI/assets/images/mapBg.png';

export const StyledContainer = styled.div`
  position: relative;
  max-width: 1120px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto 100px;

  @media (max-width: 768px) {    
    padding: 0;
    margin-bottom: 60px;
    
    div {
      flex-direction: column;
        div {
          &:nth-child(2) {
            max-width: 100%;
            span {
              margin-bottom: 25px;
            }         
          }
          &:last-child {            
            align-items: flex-start;
            div {
              flex-direction: row;
            }
          }
        }

       h2 {
         max-width: 100%;
         margin-bottom: 14px;
       }
    }
  }
`;

export const StyledFaqContainer = styled.div`
  position: relative;
  background-image: url(${faqGradient});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 182px;

  @media (max-width: 768px) {
    padding-bottom: 60px;
  }
`;
