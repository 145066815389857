import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SEO_CONSTANTS, SCHEMA_MARKUP } from 'constants/seo';
import { urlNameMaper, altUrlNameMaper } from 'constants/urlName';

export const HelmetWrapper = () => {
  const { i18n, t } = useTranslation();
  const { language } = i18n;
  const { pathname } = useLocation();

  let [pageKey] = pathname.split('/').reverse();
  if (pageKey === 'en' || pageKey === '') {
    pageKey = 'home';
  }
  const locale = language === 'en' ? 'en_US' : 'sv_SE';
  const altHrefLang = language === 'en' ? 'sv-se' : 'en-us';
  const languageToLocale = { en: 'en-us', sv: 'sv-se' };
  const url = `https://financed.se${pathname}`;
  const altUrl = language === 'en' ? `https://financed.se${pageKey !== 'home' ? `/${altUrlNameMaper[pageKey]}` : ''}`
    : `https://financed.se/en${pageKey !== 'home' ? `/${altUrlNameMaper[pageKey]}` : ''}`;
  const targetSeoData = SEO_CONSTANTS[urlNameMaper[pageKey]] || SEO_CONSTANTS[404];
  const targetSchemaMarkup = SCHEMA_MARKUP[pathname] || SCHEMA_MARKUP['/'];

  return (
    <Helmet>
      <link rel="canonical" href={url} />
      <meta name="description" content={t(targetSeoData.description)} />
      <meta name="keywords" content={t(targetSeoData.keywords)} />
      <title>{t(targetSeoData.title)}</title>
      <link rel="alternate" hrefLang={altHrefLang} href={altUrl} />
      <link rel="alternate" hrefLang={languageToLocale[language]} href={url} />
      <meta property="og:locale" content={locale} />
      <meta property="og:title" content={t(targetSeoData.title)} />
      <meta property="og:description" content={t(targetSeoData.description)} />
      <meta name="twitter:description" content={t(targetSeoData.description)} />
      <meta property="og:url" content={url} />
      <meta name="twitter:title" content={t(targetSeoData.title)} />
      <meta property="og:image:alt" content={t(targetSeoData.h1)} />
      {targetSchemaMarkup && (
        <script type="application/ld+json">{JSON.stringify(targetSchemaMarkup)}</script>
      )}
      <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async />
    </Helmet>
  );
};
