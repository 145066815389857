import React from 'react';
import { LoaderTail, LoaderWrapper } from 'UI/common/components/Loader/styled';

export const Loader = () => {
  const tails = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <LoaderWrapper>
      {tails.map((tail) => (
        <LoaderTail key={`tail-${tail}`} className={`tail-0${tail}`} />
      ))}
    </LoaderWrapper>
  );
};
