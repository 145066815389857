import {
  LARGE_LOAN_MAX_AMOUNT, LOAN_AMOUNT_LIMITS,
} from 'constants/helpers';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const fieldsInitial = {
  currentStep: 0, // From system
  contactEmail: '', // From business loan page form
  contactPhoneNumber: '', // From business loan page form
  amount: LOAN_AMOUNT_LIMITS.min, // From business loan page form or third step
  maximumLimit: LARGE_LOAN_MAX_AMOUNT, // From get offers response
  loanTermInMonths: 12, // From business loan page form or third step
  loanAgree: false, // From business loan page
  userName: '', // From identification step
  companyId: '', // On select company
  companyName: '', // On select company
  offerId: 0, // From identification step
  loanPurpose: '', // From Credit details step
  companyBusiness: '', // From Credit details step
  reportFilesNames: [], // From Credit details step
  guarantors: [ // From guarantor form
    {
      fullName: '',
      email: '',
      socialSecurityNumber: '',
    },
  ],
  summaryAgree: false, // From summary step
};

export const fieldsStore = atom({
  key: 'fieldsStore',
  default: fieldsInitial,
  effects_UNSTABLE: [persistAtom],
});
