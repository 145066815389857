import React, { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import closeMenu from 'UI/assets/images/icons/closeMenu.svg';
import { Flex } from 'UI/common/components/Flex';
import { Language } from 'UI/components/sections/Header/Language/Language';
import { Logo } from 'UI/components/sections/Header/Logo/Logo';
import { EllipseBlock } from 'UI/common/components/EllipseBlock';
import { Social } from 'UI/common/components/Social/Social';
import {
  MobWrapper,
  MobWrapperGradient,
  CloseMenu,
  WrapperMobNav,
  StyledLink,
  SocialMob,
  FirstColumnMob,
  SecondColumnMob,
} from './styled';

export const MobMenu = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  const { i18n, t } = useTranslation();
  const localePrefix = useMemo(() => (i18n.language === 'en' ? 'en/' : ''), [i18n.language]);

  useEffect(() => {
    const closeMobMenu = () => {
      if (window.innerWidth > 768) onClose();
    };

    window.addEventListener('resize', closeMobMenu);
    return () => window.removeEventListener('resize', closeMobMenu);
  }, []);

  return ReactDOM.createPortal(
    <MobWrapper>
      <MobWrapperGradient>
        <EllipseBlock />
        <Flex justify="space-between" align="center" margin="0 0 60px">
          <Logo />
          <Flex align="center">
            <Language />
            <CloseMenu onClick={onClose}>
              <img src={closeMenu} alt="closeMenu" />
            </CloseMenu>
          </Flex>
        </Flex>
        <WrapperMobNav>
          <StyledLink onClick={onClose} to="/">{t('navHome')}</StyledLink>
          <StyledLink onClick={onClose} to={`${localePrefix}${t('urlNameBusiness')}`}>{t('navBusinessLoan')}</StyledLink>
          <StyledLink onClick={onClose} to={`${localePrefix}factoring`}>{t('navFactoring')}</StyledLink>
          <StyledLink onClick={onClose} to={`${localePrefix}${t('urlNameAbout')}`}>{t('navAboutUs')}</StyledLink>
          <StyledLink onClick={onClose} to={`${localePrefix}${t('urlNameContact')}`}>{t('navContactUs')}</StyledLink>
        </WrapperMobNav>
        <SocialMob>
          <Social />
        </SocialMob>
        <Flex justify="space-between" margin="auto 0 150px">
          <FirstColumnMob>
            <p>
              Org.nr:
              {' '}
              <br />
              559269-4904
            </p>
            <p>
              Financed Sweden AB
              {' '}
              <br />
              Box 7346
              {' '}
              <br />
              103 90 Stockholm
            </p>
          </FirstColumnMob>
          <SecondColumnMob>
            <p className="phone">
              Tel:
              <br />
              <a href="tel:0843733145">
                08-43733145
              </a>
            </p>
            <p className="mail">
              Mail:
              <br />
              <a href="mailto:info@financed.se">
                info@financed.se
              </a>
            </p>
          </SecondColumnMob>
        </Flex>
      </MobWrapperGradient>
    </MobWrapper>,
    document.getElementById('portal'),
  );
};
