import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledContainer, StyledLink } from './styled';

export const Navigation = () => {
  const { i18n, t } = useTranslation();
  const localePrefix = useMemo(() => (i18n.language === 'en' ? 'en/' : ''), [i18n.language]);
  return (
    <StyledContainer>
      <StyledLink to="/">{t('navHome')}</StyledLink>
      <StyledLink to={`${localePrefix}${t('urlNameBusiness')}`}>{t('navBusinessLoan')}</StyledLink>
      <StyledLink to={`${localePrefix}factoring`}>{t('navFactoring')}</StyledLink>
      <StyledLink to={`${localePrefix}${t('urlNameAbout')}`}>{t('navAboutUs')}</StyledLink>
      <StyledLink to={`${localePrefix}${t('urlNameContact')}`}>{t('navContactUs')}</StyledLink>
    </StyledContainer>
  );
};
