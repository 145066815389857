import styled from 'styled-components';

import dots from 'UI/assets/images/dots.svg';

export const StyledContainer = styled.div`
  position: relative;
  flex: 0 1 50%;

  img {
    position: relative;
    z-index: 99;
  }

  &:before {
    content: '';
    position: absolute;
    background-image: url(${dots});
    background-repeat: no-repeat;
    background-position: top left;
    top: 50%;
    right: ${(props) => (props.pictureRight ? 0 : 'initial')};
    left: ${(props) => (props.pictureLeft ? '-42px' : 'initial')};
    transform: translateY(-50%);
    width: 40px;
    height: 179px;
  }

  img {
    max-width: 498px;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {

     &:before {
       width: 27px;
       right: ${(props) => (props.pictureRight ? '45%' : 'initial')};
       left: ${(props) => (props.pictureLeft ? '45%' : 'initial')};
       transform: rotate(90deg);
       top: -104px;
     }
  }
`;

export const EllipseBlock = styled.div`
  position: absolute;
  bottom: -44px;
  left: 0;

  img {
    position: relative;
    z-index: 0;
  }

  @media (max-width: 768px) {
    max-width: 252px;
    bottom: -24px;
    left: ${(props) => (props.pictureRight ? 'unset' : '-1px')};
    right: ${(props) => (props.pictureRight ? '-16px' : 'unset')};
  }
`;
