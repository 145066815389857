import styled from 'styled-components';

export const InputContainer = styled.div`
  position: relative;
  margin-bottom: ${(props) => props.marginBottom || 0};
  max-width: ${(props) => props.maxWidth || '403px'};
  width: 100%;

  input:focus + img, input:valid + img {
    filter: brightness(0) saturate(100%) invert(13%) sepia(41%) saturate(830%) hue-rotate(192deg) brightness(100%) contrast(90%);
  }

  .input-error {
    border: 1px solid #EB3235;
  }
  
  .error {
    position: absolute; 
    top: 51px;
    font-size: 12px;
    color: #EB3235;
    
    @media (max-width: 370px) {
      font-size: 11px;
    }
  }

  img {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 48px;
  padding: ${(props) => props.padding || '0 65px 0 24px'};
  background: ${(props) => props.background || props.theme.backgrounds?.inputBg};
  border-radius: 6px;
  border: none;
  color: ${(props) => props.theme.colors?.mainColor};
  font-weight: 500;
  font-family: 'inter', sans-serif;
  font-size: 16px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${(props) => props.theme.colors?.textColor};
    font-weight: 500;
    font-size: 16px;   
  }

  :-ms-input-placeholder {
    color: ${(props) => props.theme.colors?.textColor};
    font-weight: 500;
    font-size: 16px;
  }

  &:focus {
    outline: none;
    &::placeholder {
      color: ${(props) => props.theme.colors?.mainColor};
    }  
  }
  
  &:focus {
    &::-moz-placeholder {
      color: ${(props) => props.theme.colors?.mainColor};
      opacity: 1;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }

   @media (max-width: 768px) {
     padding: 0 50px 0 16px;     

    ::placeholder,
    ::-webkit-input-placeholder {      
      white-space: normal;
      margin: auto 0;
    }

    :-ms-input-placeholder {
      white-space: normal;
      margin: auto 0;
    }
  }

  @media (max-width: 375px) {
    ::placeholder,
    ::-webkit-input-placeholder {
      font-size: 14px;
    }
  }
`;
