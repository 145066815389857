import styled from 'styled-components';

export const PolicyWrapper = styled.article`
  padding: 15px;
  max-height: 80vh;
  width: 100%;
  min-width: 290px;
  max-width: 80vw;
  overflow: auto;
  
  h2 {
    padding: 10px 15px;
  }
`;
