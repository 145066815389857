import styled from 'styled-components';

export const StyledContainer = styled.div`
  flex: 0 1 50%;
  padding-right: 50px;

  ${(props) => props.leftSideRight && `
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  `}

   @media (max-width: 768px) {
     padding-right: 0;
     padding-top: 32px;
     order: ${(props) => (props.pictureRight ? '1' : '0')} ;

     p {
       font-size: 16px;
       line-height: 26px;
     }
   }
`;

export const StyledTileContainer = styled.div`
  max-width: 402px;
  width: 100%;   
`;

export const StyledTextContainer = styled.div`
  max-width: 460px;
  width: 100%;
`;