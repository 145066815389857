import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const partnerStore = atom({
  key: 'partnerState',
  default: '',
  effects_UNSTABLE: [persistAtom],
});
