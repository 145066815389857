import React from 'react';

import { usePathMatch } from 'customHooks/usePathMatch';
import { Items } from './Items/Items';
import { StyledContainer, StyledContent } from './styled';

export const ThreeItemsBlock = ({ dataArray }) => {
  const isLoan = usePathMatch('foretagslan', 'en/business-loans');

  return (
    <StyledContainer className={isLoan ? 'loan' : ''}>
      <StyledContent>
        <Items dataArray={dataArray} />
      </StyledContent>
    </StyledContainer>
  );
};
