import React from 'react';

import { ThreeItemsBlock } from 'UI/common/components/ThreeItemsBlock/ThreeItemsBlock';
import { TwoSectionBlock } from 'UI/common/components/TwoSectionBlock/TwoSectionBlock';
import { OurPartners } from 'UI/common/components/OurPartners/OurPartners';
import { Faq } from 'UI/common/components/Faq/Faq';
import { useGetTranslatedData } from 'utils/contentData';
import { StyledExpandedContainer } from 'UI/components/MainLayout/styled';
import { homeAndAboutFaqData } from 'constants/FaqData';
import { threeItemsHomeData } from 'constants/ThreeItemsBlockData';
import { HelmetWrapper } from 'components/HelmetWrapper/HelmetWrapper';
import { TrustPilotWidget } from 'UI/common/components/TrustWidget/TrustPilotWidget';
import { ContentBanner } from './ContentBanner/ContentBanner';
import { Banner } from './Banner/Banner';
import { StyledContainer, StyledFaqContainer, PeopleBlock } from './styled';

export const Home = () => {
  const data = useGetTranslatedData();

  return (
    <>
      <HelmetWrapper />
      <StyledExpandedContainer>
        <StyledContainer>
          <PeopleBlock />
          <Banner data={data.home.banner} />
          <TrustPilotWidget />
          <ThreeItemsBlock dataArray={threeItemsHomeData} />
          <TwoSectionBlock data={data.home} pictureRight />
        </StyledContainer>
      </StyledExpandedContainer>
      <ContentBanner data={data.home} maxWidth="620px" spanTextMargin="32px 0 44px 0" />
      <StyledContainer>
        <TwoSectionBlock data={data.home} />
      </StyledContainer>
      <StyledFaqContainer>
        <Faq faqArr={homeAndAboutFaqData} title="commonFrequentlyQuestions" />
        <OurPartners />
      </StyledFaqContainer>
    </>
  );
};
