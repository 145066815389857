import React from 'react';
import { RecoilRoot } from 'recoil';
import { AppRoutes } from 'UI/routes/AppRoutes';
import { ScrollToTop } from 'UI/routes/ScrollToTop';
import { MainLayout } from 'UI/components/MainLayout/MainLayout';
import { theme } from 'UI/common/styles/theme';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './UI/common/styles/GlobalStyles';

export const App = () => (

  <RecoilRoot>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <MainLayout>
        <ScrollToTop />
        <AppRoutes />
      </MainLayout>
    </ThemeProvider>
  </RecoilRoot>

);
