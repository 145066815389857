import styled from 'styled-components';

export const StyledContainer = styled.div`
  flex: 0 1 60%;
  padding-top: ${(props) => (props.about ? '50px' : '152px')};

  @media (max-width: 768px) {
    padding-top: 40px;
    padding-left: 15px;
    padding-right: 15px;

    h1 {
      white-space: ${(props) => (props.about && 'unset')};
    }
  }
`;

export const StyledContent = styled.div`
  max-width: 752px;
`;

export const SpanContainer = styled.div`
  max-width: 465px;

  @media (max-width: 768px) {
    max-width: ${(props) => (props.screen === 'Loan' ? '100%' : '220px')};

    span {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: ${(props) => (props.screen === 'Loan' ? '16px' : '34px')};
    }
  }
`;

export const MobileBlock = styled.div`
  a {
    font-weight: 500;
    font-size: 18px;
    margin-left: 11px;

    span {
      :hover {
        transition: all 0.3s;
        color: ${(props) => props.theme.colors?.hoverColor}
      }
    }
  }

  .block {
    display: flex;
    align-items: center;
  }

  .telBlock {
    margin-bottom: 34px;
  }
`;

export const StyledTitleWrapper = styled.div`
  max-width: ${(props) => props.width || '750px'};
  width: 100%;

  @media (max-width: 768px) {
    h2 {
      font-size: 36px;
      line-height: 52px;
      max-width: ${(props) => (props.screen === 'Loan' ? '100%' : '225px')};
      margin-bottom: ${(props) => (props.screen === 'Loan' && '0')};
    }
  }
`;

export const StyledTreeItemsContainer = styled.div`
  margin-top: 44px;

  .item__block {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    span {
      margin-left: 10px;
      display: block;
      font-size: 14px;
      font-weight: 500;

    }
  }

  @media (max-width: 768px) {
    margin-top: 0;

    .item__block {

      span {
        font-size: 12px;
      }
    }
  }
`;
