import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: relative;
  top: ${(props) => props.isContact && '127px'};
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 560px;
  height: 592px;  
  margin-top: ${(props) => (props.isContact ? '0' : '104px')};

  h3 {
    margin-top: 64px;
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 28px;
    color: #272D4E;
    text-align: center;
  }

  p {   
    font-size: 18px; 
    margin-bottom: 16px;
    color: #272D4E;
    text-align: center;
  }

  img {
    width: 281px;
    height: 397px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    top: ${(props) => props.isContact && '25px'};
    margin-top:${(props) => (props.isContact ? '0' : '20px')};
    width: 100%;
    padding: 0 28px;
    height: 559px;
    margin-bottom:${(props) => (props.isContact ? '0' : '37px')};

    h3 {
      margin-top: 44px;      
    }    

    img {
      width: 281px;
      height: 397px;
    }
  }
`;
