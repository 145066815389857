export const BASE_API_URL = process.env.RAZZLE_BACKEND_URL;

export const API_TOKEN = process.env.RAZZLE_TOKEN;
export const { SCRIVE_TOKEN } = process.env;
export const INITIAL_OFFER_URL = 'offer/';
export const OFFERS_URL = 'offers/';
export const CREATE_OFFER_URL = 'create_offer/';
export const COMPANIES_URL = 'retrieve_companies/';
export const FACTORING_URL = 'factoring/';
export const CONTACT_US_URL = 'contact_us/';

export const BASE_FETCH_SETTINGS = {
  headers: {
    'X-API-KEY': API_TOKEN,
  },
};

export const scriveHeaders = [
  `Authorization: Bearer ${process.env.RAZZLE_SCRIVE_TOKEN}`,
  'Content-Type:application/json',
  'Content-Language: sv',
];
