import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';

import { fieldsStore } from 'store/forms';
import { Flex } from 'UI/common/components/Flex';
import { Button } from 'UI/common/components/Button';
import { Loader } from 'UI/common/components/Loader/Loader';
import {
  StyledPolicy,
  StyledText,
} from 'UI/components/pages/BusinessLoan/Form/styled';
import { offerSummaryData } from 'constants/SummaryDataMapper';
import {
  OFFERS_URL,
  BASE_API_URL,
  BASE_FETCH_SETTINGS,
} from 'constants/apiConstants';
import {
  FifthStepItem,
  FifthStepHeader,
  OfferSummaryInfoWrapper,
  OfferSummaryStepValue,
  OfferSummaryInfoHeader,
  OfferSummaryInfo,
  OfferSummaryTitle,
  OfferSummaryValue,
} from 'UI/components/pages/LoanStepper/steps/summary/styled';
import { CONVERSION_TRACK_CODE, STEP } from 'constants/helpers';
import { offersStore } from 'store/offers';

export const Summary = () => {
  const [formState, setFormState] = useRecoilState(fieldsStore);
  const { offerId } = useRecoilValue(offersStore);
  const { t } = useTranslation();
  const { patch, response, loading } = useFetch(
    BASE_API_URL,
    BASE_FETCH_SETTINGS,
  );
  const { amount } = formState;
  const onCheckBoxToggle = () => {
    setFormState({ ...formState, summaryAgree: !formState.summaryAgree });
  };

  const proceedToNextStep = async () => {
    const requestBody = {
      company_registration_number: null,
      company_name: null,
      passed_step: STEP.AcceptOffer,
      offer_confirmed: true,
    };

    await patch(`${OFFERS_URL}${offerId}/`, requestBody);

    if (response.ok) {
      setFormState({ ...formState, currentStep: 6 });
    }

    if (window.gtag) {
      window.gtag('event', 'purchase', {
        event: 'purchase',
        ecommerce: {
          currency: 'SEK',
          value: amount,
          transaction_id: offerId.toString(),
          items: [
            {
              item_name: 'Företagslån',
              item_id: 'business-loan',
              price: amount,
              item_brand: 'Financed',
            },
          ],
        },
      });
    }

    // gtag_report_conversion added like script in server.js
    if (gtag_report_conversion) {
      gtag_report_conversion(false, CONVERSION_TRACK_CODE.Summary);
    }
    if (window !== undefined) window.lintrk('track', { conversion_id: 14761353 });
  };

  return (
    <FifthStepItem>
      <FifthStepHeader>{t('offerSummary')}</FifthStepHeader>
      {offerSummaryData.map(({ blockTitle, blockFields }) => (
        <OfferSummaryStepValue key={`block-${blockTitle}`}>
          <OfferSummaryInfoWrapper>
            <OfferSummaryInfoHeader>{t(blockTitle)}</OfferSummaryInfoHeader>
            <OfferSummaryInfo>
              {blockTitle !== 'guarantorInformation'
                && blockTitle !== 'creditDetails'
                && blockFields
                  .filter((f) => f.visible)
                  .map(({
                    title, value, maskChar, formatFn,
                  }) => (
                    <Flex
                      key={`field-block-${title}`}
                      justify="space-between"
                      margin="0 0 16px"
                      gap="16px"
                    >
                      <OfferSummaryTitle>
                        {formState[title] ?? t(title)}
                      </OfferSummaryTitle>
                      <OfferSummaryValue>
                        {`${formatFn(formState[value])}${maskChar}`}
                      </OfferSummaryValue>
                    </Flex>
                  ))}
              {blockTitle === 'creditDetails'
                && blockFields.map(({
                  title, value, maskChar, applyLocale,
                }) => {
                  const creditDetailsTextField = title === 'summaryCompanyBusiness'
                    || title === 'summaryLoanPurpose';
                  const financialReportsField = title === 'financialReports';

                  if (financialReportsField && !formState[value].length) { return null; }
                  return (
                    <Flex
                      key={`field-block-${title}`}
                      justify="space-between"
                      margin="0 0 16px"
                      direction={creditDetailsTextField ? 'column' : 'row'}
                      gap="16px"
                    >
                      <OfferSummaryTitle>
                        {formState[title] ?? t(title)}
                      </OfferSummaryTitle>
                      {financialReportsField ? (
                        <Flex
                          direction="column"
                          gap="16px"
                          align="end"
                          style={{ wordBreak: 'break-word' }}
                        >
                          {formState[value].map((val) => (
                            <OfferSummaryValue key={val}>
                              {`${
                                applyLocale ? val.toLocaleString('sv-SE') : val
                              }${maskChar}`}
                            </OfferSummaryValue>
                          ))}
                        </Flex>
                      ) : (
                        <OfferSummaryValue>
                          {`${
                            applyLocale
                              ? formState[value].toLocaleString('sv-SE')
                              : formState[value]
                          }${maskChar}`}
                        </OfferSummaryValue>
                      )}
                    </Flex>
                  );
                })}
              {blockTitle === 'guarantorInformation'
                && formState.guarantors.map((guarantor) => (
                  <div
                    className="guarantor-wrapper"
                    key={`guarantor-${guarantor.socialSecurityNumber}`}
                  >
                    {blockFields.map(
                      ({
                        title, value, maskChar, applyLocale,
                      }) => (
                        <Flex
                          key={`field-block-${title}`}
                          justify="space-between"
                          margin="0 0 16px"
                        >
                          <OfferSummaryTitle>{t(title)}</OfferSummaryTitle>
                          <OfferSummaryValue>
                            {`${
                              applyLocale
                                ? guarantor[value].toLocaleString('sv-SE')
                                : guarantor[value]
                            }${maskChar}`}
                          </OfferSummaryValue>
                        </Flex>
                      ),
                    )}
                  </div>
                ))}
            </OfferSummaryInfo>
          </OfferSummaryInfoWrapper>
        </OfferSummaryStepValue>
      ))}
      {loading ? (
        <Loader />
      ) : (
        <>
          <StyledPolicy>
            <Flex>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="container">
                <StyledText>{t('savesProcessesPersonalData')}</StyledText>
                <input
                  name="agree"
                  onChange={onCheckBoxToggle}
                  checked={formState.summaryAgree}
                  type="checkbox"
                />
                <span className="checkmark" />
              </label>
            </Flex>
          </StyledPolicy>
          <Button
            formButton
            onClick={proceedToNextStep}
            className={!formState.summaryAgree && 'disabled-btn'}
            disabled={!formState.summaryAgree}
          >
            {t('submitBtn')}
          </Button>
        </>
      )}
    </FifthStepItem>
  );
};
