import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BlockTitle } from 'UI/common/components/Titles/BlockTitle';
import { Text } from 'UI/common/components/Text';
import { theme } from 'UI/common/styles/theme';
import { Button } from 'UI/common/components/Button';
import { StyledContainer, StyledTileContainer, StyledTextContainer } from './styled';

export const LeftSide = ({ data, leftSideRight, pictureRight }) => {
  const { i18n } = useTranslation();
  const pathToAboutPage = useMemo(() => (i18n.language === 'en' ? '/en/about' : '/om-oss'), [i18n.language]);

  return (
    <StyledContainer leftSideRight={leftSideRight} pictureRight={pictureRight}>
      <StyledTileContainer>
        <BlockTitle
          size="28px"
          weight={700}
          marginBottom={data.titleLine2 ? '0' : '12px'}
          align={leftSideRight && 'end'}
        >
          {data.title}
        </BlockTitle>
        {data.titleLine2
                    && (
                    <BlockTitle
                      size="28px"
                      weight={700}
                      marginBottom="12px"
                      align={leftSideRight && 'end'}
                    >
                      {data.titleLine2}
                    </BlockTitle>
                    )}
      </StyledTileContainer>
      <StyledTextContainer>
        <Text
          color={theme.colors.textColor2}
          size="18px"
          marginBottom="24px"
          lineHeight="33px"
          align={leftSideRight && 'end'}
        >
          {data.text}
        </Text>
      </StyledTextContainer>
      {data.button.showButton
                && (
                <Link to={pathToAboutPage}>
                  <Button
                    readMore
                    color={theme.colors.readMoreColorBtn}
                  >
                    {data.button.text}
                  </Button>
                </Link>
                )}
    </StyledContainer>
  );
};
