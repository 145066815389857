import React from 'react';

import { socialData } from '../../../../constants/social';
import { SocialWrapper } from './styled';

export const Social = () => (
  <SocialWrapper>
    {socialData.map(({ href, element }) => (
      <a target="_blank" href={href} rel="noreferrer" key={`key-${href}`}>{element}</a>
    ))}
  </SocialWrapper>
);
