import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.h1`
  color: ${(props) => props.color || props.theme.colors?.mainColor};
  margin: ${(props) => props.margin || '0'};
  font-size: ${(props) => props.size || '76px'};
  line-height: ${(props) => props.lineHeight || 'normal'};
  font-weight: 800;
  white-space: pre-line;

  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 52px;  
  }
`;

export const Title = (props) => <StyledTitle {...props} />;
