import styled, { keyframes } from 'styled-components';

export const LoaderWrapper = styled.div`
  margin-top: 10px;
  width: 60px;
  height: 75px;
  position: relative;
`;

const fadeG = keyframes`
  0% {
    background-color: #815EFD;
  }

  100% {
    background-color: transparent;
  }
`;

export const LoaderTail = styled.div`
  position: absolute;
  background-color: transparent;
  width: 7px;
  height: 22px;
  border-radius: 4px;
  transform: scale(0.4);
  animation-name: ${fadeG};
  animation-duration: 0.832s;
  animation-iteration-count: infinite;
  animation-direction: normal;

  &.tail-01 {
    left: 0;
    top: 27px;
    animation-delay: 0.3095s;
    transform: rotate(-90deg);
  }

  &.tail-02 {
    left: 8px;
    top: 10px;
    animation-delay: 0.416s;
    transform: rotate(-45deg);
  }

  &.tail-03 {
    left: 25px;
    top: 3px;
    transform: rotate(0deg);
  }

  &.tail-04 {
    right: 8px;
    top: 10px;
    animation-delay: 0.619s;
    transform: rotate(45deg);
  }

  &.tail-05 {
    right: 0;
    top: 27px;
    animation-delay: 0.7255s;
    transform: rotate(90deg);
  }

  &.tail-06 {
    right: 8px;
    bottom: 7px;
    animation-delay: 0.832s;
    transform: rotate(135deg);
  }

  &.tail-07 {
    bottom: 0;
    left: 25px;
    animation-delay: 0.9385s;
    transform: rotate(180deg);
  }

  &.tail-08 {
    left: 8px;
    bottom: 7px;
    animation-delay: 1.035s;
    transform: rotate(-135deg);
  }
`;
