import React, { Suspense } from 'react';
import { hydrate } from 'react-dom';
import { useSSR } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { Progress } from 'components/Progress/Progerss';
import { App } from './App';
import './i18n';

import 'index.css';

const BaseApp = () => {
  useSSR(window.initialI18nStore, window.initialLanguage);
  return (
    <Suspense fallback={<Progress />}>
      <BrowserRouter>

        <App />

      </BrowserRouter>
    </Suspense>
  );
};

hydrate(
  <React.StrictMode>
    <RecoilRoot>
      <BaseApp />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept();
}
