import styled from 'styled-components';

export const StyledContainer = styled.div`
  flex: 0 1 33.333%;
  border-right: 1px solid #EDF4FF;

  &:last-child {
    border-right: none;
  }

  @media (max-width: 768px) {
    border-right: none;
  }
`;

export const StyledContent = styled.div`
  border-right: 1px solid black;
  padding: 0 43px 0 53px;

  &:last-child {
    border-right: none;
  }

  @media (max-width: 768px) {
    border-right: none;
    padding: 0 23px;
    margin-bottom: 24px;
  }
`;