import styled from 'styled-components';

export const SocialWrapper = styled.div`
  align-self: center;
  flex: 1 1 auto;
  display: flex;
  gap: 35px;
  @supports not (aspect-ratio: 1 / 1) {
    a {
      margin-right: 35px;
    }
  }
  position: relative;
  z-index: 100;

  a .social__icon {
    width: 18px;
    height: 18px;
    fill: ${(props) => props.theme.colors?.mainColor} !important;
    cursor: pointer;
  }

  &:last-child {
    margin-right: 0;
  }

  a:hover .social__icon {
    transition: all 0.3s;
    fill: ${(props) => props.theme.colors?.hoverColor} !important;
  }
`;
