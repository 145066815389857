import React from 'react';
import { useTranslation } from 'react-i18next';
import icon from 'UI/assets/images/icons/manualInputIcon.svg';
import { RangeInput } from 'components/rangeInput/rangeInput';
import { StyledRangeContainer, StyledRangeTitleBlock } from 'UI/common/components/InputRangeForm/styled';
import { LARGE_LOAN_MAX_AMOUNT, LOAN_AMOUNT_LIMITS } from 'constants/helpers';
import {
  amountRangeStep, termFormatter, LogModule, loanLarge, defineTermBoundaries,
} from './helpers';

export const InputRangeForm = ({
  values, errors, handleBlur, setFieldValue,
}) => {
  const { t } = useTranslation();

  const { amount, loanTermInMonths } = values;
  const { amount: amountError } = errors;
  const logModule = LogModule({
    minpos: 30000, maxpos: LARGE_LOAN_MAX_AMOUNT,
  });
  const amountRangeValue = logModule.position(amount < LOAN_AMOUNT_LIMITS.min
    ? LOAN_AMOUNT_LIMITS.min : amount);
  const { min: minTerm, max: maxTerm } = defineTermBoundaries(amount);

  const handleTermChange = (field, value) => {
    setFieldValue(field, value);
  };

  const handleAmountChange = (fieldName, value) => {
    const step = amountRangeStep(logModule.value(value));
    const newVal = Math.round(logModule.value(value) / step) * step;
    const { min: loanMinTerm } = defineTermBoundaries(newVal);
    setFieldValue(fieldName, newVal);
    setFieldValue('loanTermInMonths', loanLarge(amount) !== loanLarge(newVal) ? loanMinTerm : loanTermInMonths);
  };

  const handleSumInputChange = ({ target }) => {
    const value = parseInt(target.value.replace(/[^0-9]+/g, '') || 0, 10);
    const resultValue = value > LARGE_LOAN_MAX_AMOUNT ? LARGE_LOAN_MAX_AMOUNT : value;
    const { min: loanMinTerm } = defineTermBoundaries(resultValue);

    setFieldValue('amount', resultValue);
    setFieldValue('loanTermInMonths', loanLarge(amount) !== loanLarge(resultValue) ? loanMinTerm : loanTermInMonths);
  };

  return (
    <>
      <StyledRangeContainer>
        <StyledRangeTitleBlock>
          <span className="title">{t('requiredLoan')}</span>
          <div className="manual-value-input">
            <input
              type="text"
              className={
                            amountError ? 'input-error' : null
                        }
              onChange={handleSumInputChange}
              onBlur={handleBlur}
              value={parseInt(values.amount, 10).toLocaleString('sv-SE')}
              name="amount"
            />
            <img src={icon} alt="icon" />
            <span>SEK</span>
            { amountError && <p className="error">{amountError}</p>}
          </div>
        </StyledRangeTitleBlock>
        <RangeInput
          value={amountRangeValue}
          handleChange={handleAmountChange}
          minVal={30000}
          maxVal={LARGE_LOAN_MAX_AMOUNT}
          name="amount"
        />
      </StyledRangeContainer>
      <StyledRangeContainer>
        <StyledRangeTitleBlock>
          <span className="title">{t('loanDuration')}</span>
          <span className="sum">
            {termFormatter(loanTermInMonths)}
          </span>
        </StyledRangeTitleBlock>
        <RangeInput
          value={loanTermInMonths}
          handleChange={handleTermChange}
          minVal={minTerm}
          maxVal={maxTerm}
          name="loanTermInMonths"
          list="list-for-loanPeriod"
          step={12}
        />
      </StyledRangeContainer>
    </>
  );
};
