import React, { useState } from 'react';

import openMenu from 'UI/assets/images/icons/openMenu.svg';
import { MobMenu } from './MobMenu/MobMenu';
import { IconMobMenu } from './styled';

export const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpenMenu = () => {
    setIsOpen(true);
    document.body.style.overflowY = 'hidden';
  };

  const onCloseMenu = () => {
    setIsOpen(false);
    document.body.style.overflowY = 'scroll';
  };

  return (
    <>
      <IconMobMenu onClick={onOpenMenu}>
        <img src={openMenu} alt="icon-menu" />
      </IconMobMenu>
      <MobMenu isOpen={isOpen} onClose={onCloseMenu} />
    </>
  );
};
