import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const initialUserState = {};

export const userStore = atom({
  key: 'userStore',
  default: initialUserState,
  effects_UNSTABLE: [persistAtom],
});
