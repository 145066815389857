export const SEO_CONSTANTS = {
  home: {
    title: 'homeSeoTitle',
    description: 'homeSeoDescription',
    keywords: 'homeSeoKeywords',
    h1: 'offersOnBusiness',
  },
  about: {
    title: 'aboutSeoTitle',
    description: 'aboutSeoDescription',
    keywords: 'aboutSeoKeywords',
    h1: 'businessNeedsHelp',
  },
  contact: {
    title: 'contactSeoTitle',
    description: 'contactSeoDescription',
    keywords: 'contactSeoKeywords',
    h1: 'howCanHelp',
  },
  factoring: {
    title: 'factoringSeoTitle',
    description: 'factoringSeoDescription',
    keywords: 'factoringSeoKeywords',
    h1: 'multipleOffersFact',
  },
  'business-loans': {
    title: 'business-loansSeoTitle',
    description: 'business-loansSeoDescription',
    keywords: 'business-loansSeoKeywords',
    h1: 'multipleOffersBusinessLoans',
  },
  'loan-order-form': {
    title: 'stepperSeoTitle',
    description: 'stepperSeoDescription',
    keywords: 'stepperSeoKeywords',
    h1: 'stepperSeoTitle',
  },
  404: {
    title: '404SeoTitle',
    description: '404SeoDescription',
    keywords: '404SeoKeywords',
    h1: 'stepperSeoTitle',
  },
};

export const SCHEMA_MARKUP = {
  '/': {
    '@context': 'https://schema.org',
    '@type': 'Corporation',
    name: 'Financed Sweden AB',
    alternateName: 'Financed',
    url: 'https://financed.se/',
    logo: 'https://financed.se/logo-financed.svg',
    sameAs: [
      'https://www.facebook.com/financedsweden/',
      'https://www.instagram.com/financed.se/',
      'https://www.linkedin.com/company/financed-sweden/about/',
    ],
  },
  '/foretagslan': {
    '@context': 'https://schema.org',
    '@type': 'LoanOrCredit',
    name: 'Företagslån Direkt',
    description: 'Låna upp till 30 000 000 kr till ditt företag. Upp till 5 års återbetalningstid. Flexibla lösningar och villkor. Snabb och enkel prövning.',
    url: 'https://financed.se/foretagslan',
    amount: {
      '@type': 'MonetaryAmount',
      minValue: '30000',
      maxValue: '30000000',
      currency: 'SEK',
    },
    loanTerm: {
      '@type': 'QuantitativeValue',
      minValue: '1',
      maxValue: '5',
      unitCode: 'ANN',
    },
  },
  '/factoring': {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'Hur fungerar factoring?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Du laddar löpande upp de fakturor du vill sälja till vår partner. Efter kontroll av att din kund är kreditvärdig för hela beloppet tar de hela risken och du har pengarna inom ett dygn.',
        },
      },
      {
        '@type': 'Question',
        name: 'Hur kommer jag igång?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Fyll i formuläret här på webben så får du direkt kontakt med vår partner. Du får en offert inom en arbetsdag.',
        },
      },
      {
        '@type': 'Question',
        name: 'Vilka fakturor kan jag sälja?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Som köpare av tjänsten väljer du vilka fakturor du vill sälja! Det kan vara alla kundfakturor eller bara vissa.',
        },
      },
    ],
  },
  '/en/factoring': {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How does factoring work?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The invoices you would like to sell to our partner can be uploaded continuously. They check if the customer is credit-worthy of the full amount, and then take the whole risk, and you will receive money within 24 hours.',
        },
      },
      {
        '@type': 'Question',
        name: 'How do I get started?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Fill out the form online and get in touch directly with our partner. The offer is sent within one work day.',
        },
      },
      {
        '@type': 'Question',
        name: 'Which invoices can I sell?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'When buying the service, you decide which invoices to sell! It could be all of them or just a few.',
        },
      },
    ],
  },
  '/en/business-loans': {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'How much can I borrow?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'We mediate loans from 30 000 SEK to 30 000 000 SEK. How much you are able to borrow depends on the creditworthiness of your business. Please submit a loan application to find out more.',
        },
      },
      {
        '@type': 'Question',
        name: 'Who can apply for a loan?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'In order to apply for a business loan via Financed, you will need to have a key role such as managing director, member of the board or alternate member. You need to submit the company registration number in the application and confirm your identity with BankID. We will verify who you are and make sure that you work for a private limited company, which is active and registered in Sweden.',
        },
      },
      {
        '@type': 'Question',
        name: 'What is a guarantor?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'A guarantor is a person who will pay your debt if you can’t. It means giving a guarantee to the borrower.',
        },
      },
      {
        '@type': 'Question',
        name: 'How do I apply for a business loan?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: `Get started in 3 easy steps:
                Fill out the application (preferred amount, company registration number, email and phone number). The application is free of cost and not legally binding.
                Select loan offer and fill out possible extra information. You will usually be notified within 24 hours during normal business hours.
                It is up to you if you’d like to proceed once the loan offer is received. When the loan is accepted with your BankID, the money will normally be paid out the same day.`,
        },
      },
      {
        '@type': 'Question',
        name: 'How do I pay back the loan?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'The loan terms are determined by the lender. You are able to find all the information needed in the loan agreement and the invoice.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can I repay the loan in advance?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'This can be arranged with the lender.',
        },
      },
    ],
  },
};
